import React from "react"
import { useQuery } from "@apollo/client";
import gql from 'graphql-tag';
import { Loading } from './components/Common'

export const CurrentUserContext = React.createContext()

export const MeProvider = ({ children }) => {
    const {data, loading, refetch: refetchMe } = useQuery(gql`
        query {
            me {
                id
                username
                firstName
                lastName
                email
                profile {
                    id
                    lang
                    active               
                    mayDeleteSystem
                    mayDeleteClient
                    maySeePlayground
                    maySeeDemos
                    mayViewAllSchedules
                    mayExportData
                    mayCloseWorkOrders
                    numTasksPending
                    organization {
                        id
                        name
                    }
                }
            }
        }
        `)
    if (loading) return <Loading />
    const { me } = data
    return (
        <CurrentUserContext.Provider value={{ me, refetchMe }}>
            {children}
        </CurrentUserContext.Provider>
    )
}

export const useMe = () => React.useContext(CurrentUserContext)
