import React from "react";

import Grid from '@mui/material/Grid';
// import MenuItem from '@mui/material/MenuItem';
// import DatePicker from '@mui/lab/DatePicker';
import { t } from '@lingui/macro'

import {
    MyTextField, AutocompleteOptionsTextField,
} from './Common'

export const SimConfigFormComponent = ({ formData, onChange }) => {
    return (
        <>
            <Grid container spacing={2} mt={1}>
                {/* <Grid item xs={12}>
                    <MyTextField
                        label="GSE"
                        onChange={(e) => onChange({ ...formData, gse: e.target.value })}
                        value={formData.gse}
                        select
                    >
                        <MenuItem value={true}>{t`Oui`}</MenuItem>
                        <MenuItem value={false}>{t`Non`}</MenuItem>
                    </MyTextField>
                </Grid> */}
                <Grid item xs={12}>
                    {/**
                    <MyTextField
                        label="Operateur"
                        onChange={(e) => onChange({ ...formData, operateur: e.target.value })}
                        value={formData.operateur}
                    />
                    */}

                    <AutocompleteOptionsTextField
                        configKey="sim.operateur"
                        label={t`Operateur`}
                        />
                </Grid>
                <Grid item xs={12}>
                    <MyTextField
                        label={t`Numero SIM`}
                        autocomplete='off'
                        onChange={(e) => onChange({ ...formData, number: e.target.value })}
                        value={formData.number}
                    />
                </Grid>

                {/**
                <Grid item xs={12}>
                    <DatePicker
                        mask="__/__/____"
                        label="Date d'activation"
                        onChange={value => onChange({...formData, dateActivation: moment(value).format("YYYY-MM-DD")})}
                        value={formData.dateActivation}
                        fullWidth
                        variant="outlined"
                        renderInput={(params) => <MyTextField {...params} />}
                        />
                </Grid>

                <Grid item xs={12}>
                    <DatePicker
                        mask="__/__/____"
                        label="Date d'alerte"
                        onChange={value => onChange({...formData, dateAlerte: moment(value).format("YYYY-MM-DD")})}
                        value={formData.dateAlerte}
                        fullWidth
                        variant
                        renderInput={(params) => <MyTextField {...params} />}
                        />
                </Grid>
                */}

                <Grid item xs={12}>
                    <MyTextField
                        label={t`Notes`}
                        onChange={(e) => onChange({ ...formData, comments: e.target.value })}
                        value={formData.comments}
                        multiline
                        rows={3}
                    />
                </Grid>
            </Grid>
        </>
    )
}
