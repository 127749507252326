import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useSnackbar } from 'notistack';

import { useHistory } from 'react-router-dom'

import { Trans, t } from '@lingui/macro'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import {
    CustomCard,
    BasicBreadcrumbs,
    AddressForm,
    GoBackButton,
    SaveButton,
    CardButtons,
    Loading,
} from "./Common";

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import {
    MyTextField,
    DEFAULT_ADDRESS,
} from './Common'

const AddClient = () => {
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar();
    const [CreateClient, { loading, error }] = useMutation(gql`
    mutation NewClientMutation(
        $firstName:String!, 
        $lastName:String!, 
        $reference:String!, 
        $email:String!, 
        $telephone:String!,
        $primaryAddress: AddressInput,
        $billingAddress: AddressInput,
        $vatNumber:String!,
        $businessName:String!,
        $isBusiness:Boolean!,
        ){
        createClient(
          firstName:$firstName, 
          lastName:$lastName, 
          reference:$reference, 
          email:$email, 
          telephone:$telephone,
          primaryAddress:$primaryAddress,
          billingAddress:$billingAddress
          vatNumber:$vatNumber,
          businessName:$businessName,
          isBusiness:$isBusiness,
            ){
            id
            firstName
            lastName
            email
            primaryAddress{
              id
              street
            }
            billingAddress{
              id
              street
            }
        }
      }
    `)

    const [primaryAddress, setPrimaryAddress] = useState({ ...DEFAULT_ADDRESS })
    const [billingAddress, setBillingAddress] = useState({ ...DEFAULT_ADDRESS })

    const [client, setClient] = useState({
        firstName: "",
        lastName: "",
        reference: "",
        email: "",
        telephone: "",
        vatNumber: "",
        businessName: "",
        isBusiness: false,
    })

    const handleClientChange = (key, val) => {
        setClient({ ...client, [key]: val })
    }

    const [hasDifferentBillingAddress, setHasDifferentBillingAddress] = useState(false)

    const handleSubmit = async () => {
        let variables = {
            ...client,
            primaryAddress,
        }
        if (hasDifferentBillingAddress) {
            variables = { ...variables, billingAddress }
        } else {
            variables = { ...variables, billingAddress: null }
        }
        const { data } = await CreateClient({ variables })
        enqueueSnackbar(t({id: 'text.clientAdded', message: 'Client ajouté'}), {variant:'success'});
        history.push(`/clients/${data.createClient.id}/view`)
    }

    return <>
        {error && ('error saving form')}
        {loading && (<Loading />)}
        <Container maxWidth="lg">
            <BasicBreadcrumbs pages={[
                [t`Ajouter Client`]
            ]} />
        </Container>
        
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <CustomCard>
                <CardHeader title={t`Ajouter Client`} />
                <CardContent>
                    <Grid item xs={12}>
                        <Typography variant="h6" >
                            <Trans>Info</Trans>
                        </Typography>
                    </Grid>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Entreprise`}
                                control={
                                    <Checkbox
                                        onChange={(e) => handleClientChange("isBusiness", e.target.checked)}
                                        checked={client.isBusiness}
                                    />
                                }
                            />
                        </Grid>
                        
                        {client.isBusiness && <>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                onChange={(e) => handleClientChange("vatNumber", e.target.value)}
                                value={client.vatNumber}
                                label={t`Numéro de TVA`}
                                id="vatNumber"
                          
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                onChange={(e) => handleClientChange("businessName", e.target.value)}
                                value={client.businessName}
                                label={t`Nom de l'entreprise`}
                                id="businessName"
                    
                            />
                        </Grid>
                        </>}

                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                onChange={(e) => handleClientChange("firstName", e.target.value)}
                                value={client.first_name}
                                label={t`Prénom`}
                                id="firstName"
                       
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="lastName"
                                label={t`Nom de Famille`}
                                onChange={(e) => handleClientChange("lastName", e.target.value)}
                                value={client.lastName}
                      
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="email"
                                label={t`Email`}
                                onChange={(e) => handleClientChange("email", e.target.value)}
                                value={client.email}
                      
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="telephone"
                                label={t`Téléphone`}
                                onChange={(e) => handleClientChange("telephone", e.target.value)}
                                value={client.telephone}
              
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                id="reference"
                                label={t`Réference`}
                                onChange={(e) => handleClientChange("reference", e.target.value)}
                                value={client.reference}
           
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t`Adresse`}:
                            </Typography>
                        </Grid>
                        <AddressForm address={primaryAddress} onChange={address => setPrimaryAddress(address)} />
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Utiliser cette adresse en tant qu'adresse de facturation`}
                                control={
                                    <Checkbox
                                        onChange={() => setHasDifferentBillingAddress(!hasDifferentBillingAddress)}
                                        checked={!hasDifferentBillingAddress}
                                    />
                                }
                            />
                        </Grid>
                        {hasDifferentBillingAddress && <>
                            <AddressForm address={billingAddress} onChange={address => setBillingAddress(address)} />
                        </>}
                    </Grid>

                    <CardButtons>
                        <GoBackButton />
                        <SaveButton onClick={handleSubmit} disabled={loading} />
                    </CardButtons>
                </CardContent>
            </CustomCard>

        </Container>
    </>
}

export default AddClient;