import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useMe } from '../CurrentUserContext'


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import { Trans, t } from '@lingui/macro'

import { CustomCard, BasicBreadcrumbs, CardButtons, GoBackButton, SaveButton, Loading } from "./Common";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton'

import {
    MyTextField,
    AddressForm,
    ConfirmDialog,
} from './Common'

import { CLIENT_QUERY, UPDATE_CLIENT } from "../Queries";


const DEFAULT_ADDRESS = {
    street: "",
    number: "",
    city: "",
    postalCode: "",
    country: "Belgique",
}

const EditClient = () => {
    const { me } = useMe()
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { params } = useRouteMatch();
    const { id } = params;
    const [open, setOpen] = useState(false)
    const { data, loading, error, refetch } = useQuery(CLIENT_QUERY, { variables: { id: id }, fetchPolicy: 'no-cache' })
    const [EditClient, {  loading: mutationLoading }] = useMutation(UPDATE_CLIENT)
    const [DeleteClient] = useMutation(gql`
    mutation Delete($clientId:ID){
        delete(clientId:$clientId){
          ok
        }
      }
    `)

    const [client, setClient] = useState({
        firstName: "",
        lastName: "",
        reference: "",
        email: "",
        telephone: "",
        vatNumber: "",
        businessName: "",
        isBusiness: false,
    })
    const [primaryAddress, setPrimaryAddress] = useState({ ...DEFAULT_ADDRESS })
    const [billingAddress, setBillingAddress] = useState({ ...DEFAULT_ADDRESS })
    const [hasDifferentBillingAddress, setHasDifferentBillingAddress] = useState(false)
    useEffect(() => {
        if (data) {
            setClient({
                ...client,
                firstName: data.client.firstName,
                lastName: data.client.lastName,
                reference: data.client.reference,
                email: data.client.email,
                telephone: data.client.telephone,
                isBusiness: data.client.isBusiness,
                vatNumber: data.client.vatNumber,
                businessName: data.client.businessName,
            })

            if (data.client.primaryAddress) {
                setPrimaryAddress({
                    ...primaryAddress,
                    street: data.client.primaryAddress.street,
                    number: data.client.primaryAddress.number,
                    city: data.client.primaryAddress.city,
                    postalCode: data.client.primaryAddress.postalCode,
                    country: data.client.primaryAddress.country,
                })
            }

            if (data.client.billingAddress) {
                setHasDifferentBillingAddress(true)
                setBillingAddress({
                    ...billingAddress,
                    street: data.client.billingAddress.street,
                    number: data.client.billingAddress.number,
                    city: data.client.billingAddress.city,
                    postalCode: data.client.billingAddress.postalCode,
                    country: data.client.billingAddress.country,
                })
            }
        }

    }, [data])

    const handleClientChange = (key, val) => {
        setClient({ ...client, [key]: val })
    }

    const handleSubmit = async () => {
        let variables = {
            ...client,
            primaryAddress,
            clientId: data.client.id
        }
        if (hasDifferentBillingAddress) {
            variables = { ...variables, billingAddress }
        } else {
            variables = { ...variables, billingAddress: null }
        }
        if (data.client.primaryAddress) {
            variables = { ...variables, primaryAddressId: data.client.primaryAddress.id }
        }
        if (data.client.billingAddress) {
            variables = { ...variables, billingAddressId: data.client.billingAddress.id }
        }
        await EditClient({ variables })
        await refetch();
        enqueueSnackbar(t`Client modifié`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }

    const handleDelete = async () => {
        await DeleteClient({ variables: { clientId: data.client.id } })
        // await refetchClient({id:data.client.client.id})
        enqueueSnackbar(t`Client supprimé`, { variant: 'error' });
        history.push(`/clients`)
    }

    return <>
        {error && (t`error saving form`)}
        {(loading || mutationLoading) && (<Loading />)}

        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <BasicBreadcrumbs pages={[
                [`/clients/${data?.client?.id}/view`, `${data?.client?.fullName}`],
                [t`Modifier`]
            ]} />
            <CustomCard>
                <CardHeader
                    title={t`Modifier Client ${client.firstName} ${client.lastName}`}

                    action={me.profile.mayDeleteClient &&
                        <IconButton onClick={() => setOpen(true)} color="inherit">
                            <DeleteOutlineIcon />
                        </IconButton>
                    }


                >

                </CardHeader>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="h6" >
                                {t`Info`}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Entreprise`}
                                control={
                                    <Checkbox
                                        onChange={(e) => handleClientChange("isBusiness", e.target.checked)}
                                        checked={client.isBusiness}
                                    />
                                }
                            />
                        </Grid>

                        {client.isBusiness && <>
                            <Grid item xs={12} sm={6}>
                                <MyTextField
                                    onChange={(e) => handleClientChange("vatNumber", e.target.value)}
                                    value={client.vatNumber}
                                    label={t`Numéro de TVA`}
                                    id="vatNumber"

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MyTextField
                                    onChange={(e) => handleClientChange("businessName", e.target.value)}
                                    value={client.businessName}
                                    label={t`Nom de l'entreprise`}
                                    id="businessName"

                                />
                            </Grid>
                        </>}
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                onChange={(e) => handleClientChange("firstName", e.target.value)}
                                value={client.firstName}
                                label={t`Prénom`}
                                id="firstName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="lastName"
                                label={t`Nom de Famille`}
                                onChange={(e) => handleClientChange("lastName", e.target.value)}
                                value={client.lastName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="email"
                                label={t`Email`}
                                onChange={(e) => handleClientChange("email", e.target.value)}
                                value={client.email}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MyTextField
                                id="telephone"
                                label={t`Téléphone`}
                                onChange={(e) => handleClientChange("telephone", e.target.value)}
                                value={client.telephone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                id="reference"
                                label={t`Réference`}
                                onChange={(e) => handleClientChange("reference", e.target.value)}
                                value={client.reference}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">
                                {t`Adresse`}:
                            </Typography>
                        </Grid>

                        <AddressForm address={primaryAddress} onChange={address => setPrimaryAddress(address)} />

                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Utiliser cette adresse en tant qu'adresse de facturation`}
                                control={
                                    <Checkbox
                                        onChange={() => setHasDifferentBillingAddress(!hasDifferentBillingAddress)}
                                        checked={!hasDifferentBillingAddress}
                                    />
                                }
                            />
                        </Grid>
                        {hasDifferentBillingAddress && <>
                            <AddressForm address={billingAddress} onChange={address => setBillingAddress(address)} />
                        </>}
                    </Grid>

                    <CardButtons>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>
            <ConfirmDialog
                open={open}
                title={"Confirmation suppression Client"}
                body={<Trans>
                    Attention vous êtes sur le point de supprimer le client {data?.client?.fullName}.<br />
                    Les systèmes, entretiens, commentaires, sim et documents de ce client seront également supprimés.<br /><br />
                    Veuillez confirmer l'action de suppression.</Trans>
                }
                onClose={() => setOpen(false)}
                onSubmit={handleDelete}
            />
        </Container>
    </>
}

export default EditClient;
