import React, { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

import { useHistory, useParams } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
    CustomCard,
    Loading,
} from './Common'
import { t } from '@lingui/macro'

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import EventIcon from '@mui/icons-material/Event';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';

import {
    Button,
    IconButton,
    Stack,
    CardContent,
    CardHeader,
} from '@mui/material'

import {
    MyTextField,
    NarrowPageContainer,
} from './Common'

import moment from 'moment';
import { TaskCardContent } from "./Tasks";
import { useMe } from "../CurrentUserContext";

import CssBaseline from '@mui/material/CssBaseline';

import { PrintableTheme } from '../Themes'
import { ThemeProvider } from '@mui/material/styles';


const SCHEDULE_QUERY = gql`
query ScheduleQuery ( $fromDate: String!, $toDate: String!, $userId: ID ) {
    users {
        id
        username
        firstName
    }
    schedule ( fromDate: $fromDate, toDate: $toDate, userId: $userId ) {
        date
        appointments {
            id
            type
            datetime
            client {
                id
                fullName
            }
            address {
                id
                formatted
            }
        }
        tasks {
            id
            state
            description
            dueDate
            createdAt
            tags {
                id
                name
            }
            createdBy {
                id
                username
                firstName
            }
            assignees {
                id
                firstName
                username
            }
        }
    }
}
`;

export const PrintableDailySchedule = () => {
    const { userId, date } = useParams();
    const { data, loading, error } = useQuery(SCHEDULE_QUERY, {
        variables: {
            fromDate: date,
            toDate: date,
            userId,
        },
        fetchPolicy: 'no-cache',
    })
    useEffect(() => {
        setInterval(() => {
            if (!loading && !error && data) {
                window.print()
                window.close()
            }
        }, 100)
    }, [loading, error, data])
    if (loading) return <Loading />
    if (error) return 'error'
    const { appointments } = data.schedule[0];
    return (<>
        <ThemeProvider theme={PrintableTheme}>
            <CssBaseline />
            <h1>{date}</h1>
            {appointments.map(appointment => {
                return (
                    <Stack direction="row" sx={{ alignItems: 'center' }} spacing={2}>
                        <EventIcon fontSize="large" />
                        <Box p={1}>
                            <Typography variant="body2">{appointment.client.fullName}</Typography>
                            <Typography variant="h6">{moment(appointment.datetime).format('LLL')}</Typography>
                            <Typography>{appointment.address.formatted}</Typography>
                        </Box>
                    </Stack>
                )
            })}
        </ThemeProvider>
    </>)
}

export const ScheduleComponent = () => {
    const { me } = useMe();
    const history = useHistory()
    const [userId, setUserId] = useState(me.id)
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().add(30, 'days').format('YYYY-MM-DD'))
    const { data, loading, error, refetch } = useQuery(SCHEDULE_QUERY, {
        variables: {
            fromDate,
            toDate,
            userId,
        },
        fetchPolicy: "no-cache",
    })
    useEffect(() => {
        refetch({
            fromDate,
            toDate,
            userId,
        })
    }, [userId])
    const schedule = data?.schedule || []
    const users = data?.users || []
    if (error) return 'error'
    return (
        <>
            <NarrowPageContainer>
                <Stack mb={2} direction="row" spacing={1}>
                    <Button
                        variant="outlined"
                        onClick={() => history.push(`/tasks/add`)}
                        startIcon={<AddIcon />}
                        >
                        {t`Ajouter une tâche`}
                    </Button>
                    {/**
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => alert('@TODO create and link to a new appointment view')}
                        startIcon={<AddIcon />}
                        >
                        Ajouter un rdv
                    </Button>
                    */}
                </Stack>

                {me.profile.mayViewAllSchedules && (
                    <CustomCard>
                        <CardHeader title={t`Technicians`} />
                        <CardContent>
                            <Stack direction="row" spacing={1}>
                                {users.map(u => (
                                    <Button variant="outlined" key={u.id} onClick={e => setUserId(u.id)} disabled={u.id === userId}>{u.firstName}</Button>
                                ))}
                            </Stack>
                        </CardContent>
                    </CustomCard>
                )}

                <CustomCard>
                    <CardHeader title={t`Mon planning`} />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <DesktopDatePicker
                                    label={t`A partir de` + ` ...`}
                                    inputFormat="yyyy-MM-DD"
                                    value={fromDate}
                                    mask="____-__-__"
                                    onChange={d => setFromDate(d.format('YYYY-MM-DD'))}
                                    renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DesktopDatePicker
                                    label={t`Jusqu'au` + ` ...`}
                                    inputFormat="yyyy-MM-DD"
                                    value={toDate}
                                    mask="____-__-__"
                                    onChange={d => setToDate(d.format('YYYY-MM-DD'))}
                                    renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                                    />
                            </Grid>
                        </Grid>
                    </CardContent>
                </CustomCard>

                {loading && <Loading />}
                
                {schedule.map(record => {
                    const dateString = moment(record.date).format('YYYY-MM-DD');
                    return (
                        <CustomCard>
                            <CardHeader
                                title={dateString}
                                action={
                                    <IconButton color="inherit" component="label" onClick={() => {
                                        window.open(`/printable/daily-schedule/${userId}/${dateString}`);
                                    }}>
                                        <PrintIcon />
                                    </IconButton>
                                }
                                />
                            <CardContent>    
                                <Stack direction="column" spacing={2}>
                                    {record.tasks.length > 0 && (<>{record.tasks.map(task => <TaskCardContent task={task} /> )}</>)}
                                    {record.appointments.length > 0 && (
                                        <>
                                            {record.appointments.map(appointment => {
                                                return (
                                                    <Stack direction="row" sx={{ alignItems: 'center', cursor: 'pointer' }} spacing={2} onClick={() => history.push(`/appointments/${appointment.id}`)}>
                                                        <EventIcon fontSize="large" />
                                                        <Box p={1}>
                                                            <Typography variant="body2" color="text.disabled">{appointment.client.fullName}</Typography>
                                                            <Typography variant="h6">{moment(appointment.datetime).format('LLL')}</Typography>
                                                            <Typography>{appointment.address.formatted}</Typography>
                                                        </Box>
                                                    </Stack>
                                                )
                                            })}
                                        </>
                                    )}
                                </Stack>
                            </CardContent>
                        </CustomCard>
                    )
                })}
            </NarrowPageContainer>
        </>
    )
}
