import React, { useState } from "react";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";
import gql from 'graphql-tag';

import { useSnackbar } from 'notistack';

import { SYSTEM_QUERY, ADD_MAINTENANCE_LOG, UPDATE_MAINTENANCE_LOG } from "../Queries";
import { Trans, t } from '@lingui/macro'

import {
    CustomCard,
    Comments,
    DocumentUpload,
    MultiSelect,
    BasicBreadcrumbs,
    MyTextField,
    valueOrNd,
    MyPopover,
    toFrDate,
    Loading,
    IconBarComponent,
} from './Common'

import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    Divider,
} from '@mui/material'

import DatePicker from '@mui/lab/DatePicker';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NoSimTwoToneIcon from '@mui/icons-material/NoSimTwoTone';
import SimCardTwoToneIcon from '@mui/icons-material/SimCardTwoTone';
import AddIcon from '@mui/icons-material/Add';

import ModeEditIcon from '@mui/icons-material/ModeEdit';

import moment from 'moment';
import 'moment/locale/fr'

// const VIDEO = [
//     ['type_nvr', "Type NVR"],
//     ['url_nvr', "URL NVR"],
//     ['ip_nvr', "IP NVR"],
//     ['marque', "Marque"],
//     ['port_http', "Port HTTP"],
//     ['port_service', 'Port Service'],
//     ['port_serveur', 'Port Serveur'],
//     ['username', 'Nom d\'utilisateur'],
//     ['dns', 'DNS'],
//     ['host_name', 'Nom d\'hôte'],
//     ['password', 'Mot de passe'],
//     ['nbre_camera', 'Nbre de camera'],
//     ['switch_poe', 'Switch POE'],
// ]
// 
// const SECURITY_ALARM = [
//     ['code_technique', "Code technique"],
//     ['marque', "Marque"],
//     ['modele', "Modèle"],
//     ['type', "Type"],
//     ['incert', "Incert"],
//     ['transmission', "Transmission client"],
//     ['gsm', "Gsm"],
//     ['app', "App"],
//     ['monitoring', "Monitoring"],
//     ['ip_centrale', "IP Centrale"],
//     ['port_centrale', "Port Centrale"],
//     ['nom_centrale', "Nom Centrale"],
//     ['num_prom', 'Num prom'],
//     ['protocole', 'Protocole'],
//     ['nbre_detecteurs', "Nbre détécteurs"],
//     ['nbre_contacteurs', "Nbre contacteurs"],
//     ['nbre_claviers', "Nbre claviers"],
//     ['nbre_sirenes', "Nbre sirènes"],
//     ['nbre_groupes', "Nbre groupes"],
//     ['acces_distance', 'Accès à distance'],
// ]

// const FIRE_ALARM = [
//     ['code_technique', 'Code technique'],
//     ['marque', 'Marque'],
//     ['modele', 'Modèle'],
//     ['analyse_risque', 'Analyse de risque'],
//     ['conforme', 'Conforme'],
//     ['standard', 'Standard'],
//     ['nbre_detecteurs', 'Nbre de détecteurs'],
//     ['type_detecteur', 'Type de détecteur'],
//     ['localisation_detecteur', 'Localisation détecteur'],
//     ['repetiteur', 'Répétiteur'],
//     ['ip', 'IP'],
//     ['port', 'Port'],
//     ['transmission', "Transmission client"],
//     ['gsm', "Gsm"],
//     ['app', "App"],
//     ['monitoring', "Monitoring"],
//     ['ip_centrale', "IP Centrale"],
//     ['port_centrale', "Port Centrale"],
//     ['nom_centrale', "Nom Centrale"],
//     ['num_prom', 'Num prom'],
//     ['protocole', 'Protocole'],
//     ['acces_distance', 'Accès à distance'],
// ]
// 
// const ACCESS_CONTROL = [
//     ['code_technique', 'Code technique'],
//     ['marque', 'Marque'],
//     ['modele', 'Modèle'],
//     ['type', 'Type'],
//     ['protocole_tetes', 'Protocol têtes'],
//     ['carte', 'Type carte'],
//     ['controleurs', 'Controleurs'],
//     ['modele_controleurs', 'Modèle controleur'],
//     ['nbre_controleurs', 'Nbre controleurs'],
//     ['localisation_controleurs', 'Localisation controleurs'],
//     ['nbre_portes', 'Nbre portes'],
//     ['ip', 'IP'],
//     ['port', 'Port'],
//     ['acces_distance', 'Accès à distance'],
// ]

// const EVEN_COOLER_MAP = { VIDEO, ACCESS_CONTROL, FIRE_ALARM, SECURITY_ALARM }

const SystemInfo = ({ system }) => {
    
    return <>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Adresse`}
            </Typography>
            {system.address.formatted}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Date d'installation`}
            </Typography>
            {valueOrNd(toFrDate(system.dateInstallation))}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {system.installers.length > 1 ? t`Installateurs` : t`Installateur`}
            </Typography>
            {system.installers.map((i) => (
                (system.installers.indexOf(i) == 0) ?
                    i.firstName :
                    (system.installers.indexOf(i) < system.installers.length - 1) ?
                        `, ${i.firstName}` :
                        ` et ${i.firstName}`
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Dernier entretien`}
            </Typography>
            {valueOrNd(toFrDate(system.lastMaintenanceDate))}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                Omnium
            </Typography>
            {system.omnium ? t`Oui` : t`Non`}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Contrat d'entretien`}
            </Typography>
            {system.contratEntretien ? t`Oui` : t`Non`}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Date limite entretien`}
            </Typography>
            {valueOrNd(toFrDate(system.maintenanceDueDate))}
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
                {t`Identifiant`}
            </Typography>
            {system.identifier}
        </Grid>


    </>
}

const DEFAULT_MLOG = {
    installers: [],
    notes: "",
    interventionDate: moment().format("YYYY-MM-DD"),
    editMaintenanceLog: false,
    id: "",
}

const System = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { params } = useRouteMatch();
    const { id } = params;
    const history = useHistory();

    const [comment, setComment] = useState("")
    const [log, setLog] = useState(DEFAULT_MLOG)
    const [open, setOpen] = useState(false);

    const { data, loading, refetch } = useQuery(SYSTEM_QUERY, { variables: { id: id } })
    const { data: installersData, loading: installersLoading, error: installersError } = useQuery(gql`
    query{ users{
        id
        username
        firstName
    }}`)
    const [addMaintenanceLog] = useMutation(ADD_MAINTENANCE_LOG)
    const [updateMaintenanceLog] = useMutation(UPDATE_MAINTENANCE_LOG)
    const [addComment] = useMutation(gql`
    mutation SystemComment($systemId:ID!, $text:String!){
        createSystemComment(systemId:$systemId, text:$text){
          id
        }
      }`)

    const [updateSystemComment] = useMutation(gql`
      mutation UpdateComment($id:ID!, $text:String!){
          updateSystemComment(id:$id, text:$text){
            id
          }
        }
      `)

    const [deleteSystemComment] = useMutation(gql`
    mutation Delete($systemCommentId:ID){
        delete(systemCommentId:$systemCommentId){
          ok
        }
      }
    `)
    const [addDocument, { data: mutationDocumentData }] = useMutation(gql`
        mutation addDocument($systemId:ID, $file:Upload!){
            docUpload(systemId:$systemId, file:$file){
              id
            }
          }`)

    if (loading || installersLoading) return <Loading />

    const { system } = data
    const {
        client,
        documents,
    } = system

    const handlePostComment = async () => {
        await addComment({
            variables: {
                systemId: id,
                text: comment,
            }
        })
        await refetch()
        enqueueSnackbar(t`Commentaire ajouté`, { variant: 'success' });
        setComment("")
    }

    const handleFileChange = async (e) => {
        await addDocument({
            variables: {
                file: e.target.files[0],
                systemId: id,
            }
        })
        await refetch()
        enqueueSnackbar(t`Document ajouté`, { variant: 'success' });
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setLog(DEFAULT_MLOG)
        setOpen(false);
    };

    const handleFormChange = (key, val) => {
        setLog({ ...log, [key]: val })
    }

    const handleMaintenanceLogSave = async () => {
        const temp = users.filter(x => log.installers.includes(x.firstName))
        const installers_ids = []
        temp.forEach(x => installers_ids.push(x.id))

        if (log.editMaintenanceLog) {
            await updateMaintenanceLog({
                variables: {
                    id: log.id,
                    notes: log.notes,
                    installers: installers_ids,
                    entryDate: log.interventionDate,
                }
            })
            enqueueSnackbar(t`Rapport modifié`, { variant: 'success' });

        }
        else {
            await addMaintenanceLog({
                variables: {
                    systemId: id,
                    notes: log.notes,
                    installers: installers_ids,
                    entryDate: log.interventionDate,
                }
            })
            enqueueSnackbar(t`Rapport ajouté`, { variant: 'success' });

        }
        await refetch()
        setLog(DEFAULT_MLOG)
        setOpen(false)
    }

    const handleUpdateComment = async (comment) => {
        await updateSystemComment({ variables: comment })
        await refetch()
        enqueueSnackbar(t`Commentaire modifié`, { variant: 'success' });
    }
    const handleDeleteComment = async (id) => {
        await deleteSystemComment({ variables: { systemCommentId: id } })
        await refetch()
        enqueueSnackbar(t`Commentaire supprimé`, { variant: 'success' });
    }
    
    const users = installersData.users.filter(u => u.username !== "root");

    const lTableCellSx = { padding: '.5rem 0 .5rem .5rem', fontWeight: 'bold' }
    const rTableCellSx = { padding: '.5rem .5rem .5rem 0' }

    return <>
        <Container maxWidth="lg" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${client.id}/view`, `${client.fullName}`],
                [ system.type ],
            ]} />
            <Stack direction="row" mb={2} spacing={2} alignItems="center">
                <Typography variant="h4">
                    {system.type} - {client.fullName}
                </Typography>
                <Stack spacing={1} direction="row">
                    <IconBarComponent icons={system.icons} fontSize="large" />
                </Stack>
            </Stack>

            <Stack mb={2} direction="row" spacing={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push(`/systems/${system.id}/addappointment`)}
                    startIcon={<AddIcon />}
                >
                    {t`Ajouter un rdv`}
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => history.push(`/clients/${client.id}/tasks/add`)}
                    startIcon={<AddIcon />}
                >
                    {t`Ajouter une tâche`}
                </Button>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <CustomCard>
                        <CardHeader
                            title={t`Information Generale`}
                            action={
                                <IconButton color="inherit" onClick={() => history.push(`/editsystem/${id}`)}>
                                    <ModeEditIcon />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <SystemInfo system={system} />
                            </Grid>
                        </CardContent>
                    </CustomCard>

                    <CustomCard>
                        <CardHeader
                            title={t`Rapports d'entretiens`}
                            action={
                                <IconButton color="inherit" onClick={handleClickOpen}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Stack spacing={2}>
                                {system.maintenanceLogs.length > 0 ? system.maintenanceLogs.map((mlog) => (
                                    <Paper variant="outlined">
                                        <Stack m={1.5}>
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: "center" }}>
                                                <Typography variant="h6">{t`Entretien fait le`} {moment(mlog.entryDate).format('DD/MM/YYYY')}</Typography>
                                                <IconButton color="inherit" onClick={() => {
                                                    setLog({
                                                        ...log,
                                                        notes: mlog.notes,
                                                        interventionDate: moment(mlog.interventionDate).format("YYYY-MM-DD"),
                                                        editMaintenanceLog: true,
                                                        id: mlog.id,
                                                        installers: mlog.installers.map(i => i.firstName)
                                                    })
                                                    setOpen(true)
                                                }}>
                                                    <ModeEditIcon />
                                                </IconButton>
                                            </Stack>
                                            <Typography><span style={{ fontWeight: 'bold' }}>{t`Installateurs`}:</span> {mlog.installers.map(user => `${user.firstName} `)}</Typography>
                                            <Stack direction="row" spacing={1}>
                                                <Typography component="span" sx={{ fontWeight: 'bold' }}>Notes:</Typography>
                                                <Box>{valueOrNd(mlog.notes)}</Box>
                                            </Stack>
                                        </Stack>
                                    </Paper>

                                )) :
                                    <Typography align="center">
                                        {t`Pas d'entretiens (Ajoutez un rapport pour l'installation)`}
                                    </Typography>
                                }
                            </Stack>
                        </CardContent>
                    </CustomCard>
                    
                    <DocumentUpload
                        handleChange={handleFileChange}
                        documents={documents}
                    />

                    <Comments
                        comments={system.comments}
                        comment={comment}
                        handleChange={(e) => setComment(e.target.value)}
                        handlePostComment={handlePostComment}
                        handleUpdateComment={handleUpdateComment}
                        handleDeleteComment={handleDeleteComment}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <CustomCard>
                        <CardHeader
                            title={t`Information technique`}
                            action={
                                <IconButton color="inherit" onClick={() => history.push(`/editsystemconfig/${id}`)}>
                                    <ModeEditIcon />
                                </IconButton>
                            }
                        />
                        <CardContent>
                            <Table sx={{
                                [`& .${tableCellClasses.root}`]: {
                                    borderBottom: "none"
                                }
                            }}
                                size="medium"
                                padding="none"
                            >
                                <TableBody>

                                    {system.template.schema.fields.map(f => {
                                        // EVEN_COOLER_MAP[system.type].map((x) => {
                                        if(!(system.config[f.name] == "" || system.config[f.name] == 0 || system.config[f.name] == undefined)) {
                                            return <TableRow hover={true}>
                                                    <TableCell sx={lTableCellSx}>
                                                        {f.displayName}
                                                    </TableCell>
                                                    <TableCell sx={rTableCellSx} align="right">
                                                        {(typeof system.config[f.name] == "boolean" && system.config[f.name]) ? t`Oui`
                                                            : (typeof system.config[f.name] == "boolean") ? t`Non`
                                                            : system.config[f.name]}
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    })}
                                    
                                    {system.config.info_connexion &&
                                    <TableRow hover={true}>
                                        <TableCell sx={lTableCellSx}>
                                            {t`Info connexion`}
                                        </TableCell>
                                    </TableRow>}

                                </TableBody>
                            </Table>
                            {system.config.info_connexion &&
                                <Typography p={1} color="text.disabled">{system.config.info_connexion}</Typography>
                            }
                            <Box my={1}>
                                <Divider light />
                            </Box>

                            {system.sim ? (
                                <>
                                    <Stack direction="row" mb={1} mt={-0.5}>
                                        {/** <SimCardTwoToneIcon /> */}
                                        <Typography p={1} style={{ fontWeight: 'bold' }}>
                                            {t`sim activée`.toUpperCase()}
                                        </Typography>
                                        <IconButton sx={{ marginLeft: "auto" }} color="inherit" onClick={() => history.push(`/editsim/${system.id}`)}>
                                            <ModeEditIcon />
                                        </IconButton>
                                    </Stack>
                                    <Table sx={{
                                        [`& .${tableCellClasses.root}`]: {
                                            borderBottom: "none"
                                        }
                                    }}
                                        size="medium"
                                        padding="none"
                                    >
                                        <TableBody>
                                            <TableRow hover={true}>
                                                <TableCell sx={lTableCellSx}>{t`Activée`}</TableCell>
                                                <TableCell sx={rTableCellSx} align="right">{valueOrNd(toFrDate(system.sim.dateActivation))}</TableCell>
                                            </TableRow>
                                            <TableRow hover={true}>
                                                <TableCell sx={lTableCellSx}>{t`Operateur`}</TableCell>
                                                <TableCell sx={rTableCellSx} align="right">{valueOrNd(system.sim.operateur)}</TableCell>
                                            </TableRow>
                                            <TableRow hover={true}>
                                                <TableCell sx={lTableCellSx}>{t`Numero SIM`}</TableCell>
                                                <TableCell sx={rTableCellSx} align="right">{valueOrNd(system.sim.number)}</TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>


                                    {system.sim.comments && (
                                        <>
                                            <Typography p={1} color="text.disabled">{system.sim.comments}</Typography>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Stack direction="row" alignItems="center" gap={1} mt={2}>
                                    <NoSimTwoToneIcon color="disabled" />
                                    <Typography>{t`SANS CARTE SIM`}</Typography>
                                    <IconButton sx={{ marginLeft: "auto" }} color="inherit" onClick={() => history.push(`/addsim/${id}`)}>
                                        <AddCircleOutlineIcon />
                                    </IconButton>
                                </Stack>
                            )}


                        </CardContent>
                    </CustomCard>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>{t`Ajouter rapport d'entretien`}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <DatePicker
                            label={t`Date d'intervention`}
                            inputFormat="DD/MM/YYYY"
                            value={log.interventionDate}
                            onChange={(e) => handleFormChange("interventionDate", e.format("YYYY-MM-DD"))}
                            renderInput={(params) => <MyTextField {...params} />}
                        />
                        <MyTextField
                            id="notes"
                            label={t`Notes`}
                            value={log.notes}
                            onChange={(e) => handleFormChange("notes", e.target.value)}
                            multiline
                            rows={3}
                        />
                        <MultiSelect
                            values={log.installers}
                            onChange={(e) => handleFormChange("installers", [...e.target.value])}
                            users={users}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t`Annuler`}</Button>
                    <Button variant="contained" onClick={handleMaintenanceLogSave}>{t`Sauvgarder`}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    </>
}


export default System;
