import React, { useState, useEffect, useCallback } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useSnackbar } from 'notistack';

import { useHistory, useRouteMatch } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
    CustomCard,
    BasicBreadcrumbs,
    valueOrNd,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading,
    prettyPrintSystem,
} from "./Common";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import {
    MultiSelectUsers,
} from './Common'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import NavigationIcon from '@mui/icons-material/Navigation';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbSharpIcon from '@mui/icons-material/DoDisturbSharp';

import {
    MenuItem,
    Button,
    IconButton,
    Stack,
    Divider,
} from '@mui/material'

import {
    MyTextField,
    ConfirmDialog,
    MultiSelectSystems
} from './Common'

import moment from 'moment';
import { useMe } from "../CurrentUserContext";
import { Trans, t } from '@lingui/macro'


// @TODO .. store templates in the backend, user controlled, and allow them to select from them
// when configuring notififications:

// const REMINDER_TEMPLATE = `Rappel: un rendez-vous est prévu demain, le %DATE% à %HEURE% pour %REASON%.
// En cas d'empêchement, veuillez nous prévenir par téléphone, sms, ou email à %EMAIL%.
// Bien a vous,
// Patrick de la societe GSE`;

const REMINDER_TEMPLATE = ``

const APPOINTMENT_TYPE_MAP = {
    "MAINTENANCE": <Trans>Entretien</Trans>,
    "ESTIMATE": <Trans>Devis</Trans>,
    "INSTALLATION": <Trans>Installation</Trans>,
}

const MyTimePicker = ({ initialValue, onChange }) => {
    const hourChoices = [
        '06', '07', '08', '09', '10', '11',
        '12', '13', '14', '15', '16', '17',
        '18', '19', '20',
    ]
    const minChoices = ['00', '15', '30', '45']
    const choices = [];
    hourChoices.forEach(h => {
        minChoices.forEach(m => {
            choices.push(`${h}:${m}`)
        })
    })
    return (
        <MyTextField
            label={t`Heure`}
            value={initialValue}
            select
            onChange={e => onChange(e.target.value)}
        >
            {choices.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
        </MyTextField>
    )
}


const DEFAULT_SMS_REMINDER_VALUES = {
    date: null,
    time: '17:00',
    text: REMINDER_TEMPLATE,
    number: '',
}

const DEFAULT_APPOINTMENT_VALUES = {
    systems: [],
    datetime: '',
    notes: '',
    durationMinutes: 60,
    technicians: [],
    smsReminder: { ...DEFAULT_SMS_REMINDER_VALUES }
}

const REASONS = {
    INSTALLATION: t`l'installation de votre système`,
    ESTIMATE: t`votre devis`,
    MAINTENANCE: t`l'entretien de votre système`,
}

const AppointmentForm = ({ values, onChange, telephone, currentUser, type }) => {
    values = values || { ...DEFAULT_APPOINTMENT_VALUES }

    const [formDate, setFormDate] = useState(values.datetime ? moment(values.datetime) : moment())
    const [formTime, setFormTime] = useState(values.datetime ? moment(values.datetime).format('HH:mm') : '08:00')
    const formattedDatetime = () => formDate ? `${formDate.format('YYYY-MM-DD')} ${formTime}` : null;
    const updateFormDatetime = () => onChange({ ...values, datetime: formattedDatetime() });

    useEffect(() => {
        updateFormDatetime();
    }, [formDate, formTime])

    const { datetime } = values

    useEffect(() => {
        if (formattedDatetime() !== datetime) {
            setFormDate(moment(datetime));
            setFormTime(moment(datetime).format('HH:mm'))
        }
    }, [datetime, setFormDate, setFormTime])

    // SMS reminder config:
    const [sendSMS, setSendSMS] = useState(values.smsReminder === true)
    const [smsReminderDate, setSmsReminderDate] = useState(null)
    const [smsReminderTime, setSmsReminderTime] = useState(values.smsReminder?.time || '17:00')
    const [smsText, setSmsText] = useState(values.smsReminder?.text || REMINDER_TEMPLATE)
    const [smsNumber, setSmsNumber] = useState(
        sendSMS
            ? (values.smsReminder?.number
                ? values.smsReminder.number
                : telephone
            )
            : telephone
    )
    const [customSMS, setCustomSMS] = useState(false)
    useEffect(() => {
        if (formDate) {
            setSmsReminderDate(formDate.clone().subtract(1, 'days'))
        } else {
            setSmsReminderDate(null)
        }
    }, [formDate])
    useEffect(() => {
        if (sendSMS) {
            onChange({
                ...values, smsReminder: {
                    ...values.smsReminder,
                    date: smsReminderDate ? smsReminderDate.format('YYYY-MM-DD') : null,
                    time: smsReminderTime,
                    text: smsText,
                    number: smsNumber,
                }
            })
        } else {
            onChange({ ...values, smsReminder: null })
        }
    }, [smsReminderDate, smsReminderTime, smsText, smsNumber, sendSMS])
    const reminderTimeOptions = [
        '08:00', '09:00', '10:00', '11:00', '12:00',
        '13:00', '14:00', '15:00', '16:00', '17:00',
        '18:00', '19:00', '20:00',
    ];
    const interpolatedSmsText = smsText
        .replaceAll('%TIME%', formTime ? formTime : '')
        .replaceAll('%HEURE%', formTime ? formTime : '')
        .replaceAll('%DATE%', formDate ? formDate.format('DD/MM/YYYY') : '')
        .replaceAll('%EMAIL%', currentUser.email ? currentUser.email : '')
        .replaceAll('%REASON%', type in REASONS ? REASONS[type] : '')
        ;


    const mayDelegate = true

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={5}>
                <DesktopDatePicker
                    label={t`Date de l'entretien`}
                    inputFormat="DD/MM/YYYY"
                    value={formDate}
                    mask="__/__/____"
                    onChange={e => {
                        const datetime = e ? `${e.format('YYYY-MM-DD')} ${formTime}` : null;
                        setFormDate(e)
                        onChange({ ...values, datetime })
                    }}
                    renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <MyTimePicker
                    initialValue={formTime}
                    onChange={newTime => {
                        setFormTime(newTime)
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <MyTextField
                    // @TODO Should this be hours? Allow fractional hours (eg 0.5)?
                    //       Use neither and instead have an "end time" variables mapped to a db datetime?
                    label={t`Durée prévue (en minutes)`}
                    value={values.durationMinutes}
                    onChange={e => onChange({ ...values, durationMinutes: e.target.value })}
                />
            </Grid>

            {mayDelegate && (
                <>
                    <Grid item xs={12}>
                        <MultiSelectUsers
                            label={t`Techniciens`}
                            selectedUserIds={values.technicians}
                            onChange={ids => onChange({ ...values, technicians: ids })}
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12}>
                <MyTextField
                    value={values.notes}
                    onChange={e => onChange({ ...values, notes: e.target.value })}
                    placeholder={t`Information additionnelle...`}
                    multiline
                    rows={5}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControlLabel
                    label={t`Programmer un rappel SMS pour le client?`}
                    control={
                        <Checkbox
                            onChange={(e) => setSendSMS(e.target.checked)}
                            checked={sendSMS}
                        />
                    }
                />
            </Grid>

            {sendSMS && (
                <>
                    <Grid item xs={12} sm={6}>
                        <MyTextField
                            label={t`Date du rappel`}
                            disabled={true}
                            // onChange={(e) => onChange({ ...smsReminder, date: e.target.value })}
                            value={smsReminderDate?.format('DD/MM/YYYY')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField
                            label={t`Heure du rappel`}
                            value={smsReminderTime}
                            onChange={e => {
                                setSmsReminderTime(e.target.value);
                                // onChange({...smsReminder, time: e.target.value }
                            }}
                            select
                        >
                            {reminderTimeOptions.map(o => <MenuItem value={o}>{o}</MenuItem>)}
                        </MyTextField>
                    </Grid>
                    <Grid item xs={12}>
                        <MyTextField
                            label={t`Numero GSM du client`}
                            value={smsNumber}
                            onChange={e => setSmsNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <MyTextField
                                label={t`Le SMS`}
                                multiline
                                value={customSMS ? smsText : interpolatedSmsText}
                                disabled={!customSMS}
                                onChange={e => setSmsText(e.target.value)}
                                rows={5}
                            />

                            <FormControlLabel
                                label={t`Modifier le modèle SMS?`}
                                control={
                                    <Checkbox
                                        onChange={(e) => setCustomSMS(e.target.checked)}
                                        checked={customSMS}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </>
            )}
        </Grid>
    )
}


export const AddMaintenanceAppointmentComponent = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { params } = useRouteMatch();
    const { systemId } = params;
    const history = useHistory()
    const [form, setForm] = useState({ ...DEFAULT_APPOINTMENT_VALUES, systems: [systemId] })
    const { me } = useMe()
    const { data, loading, error } = useQuery(gql`
        query SystemQuery ( $id: ID! ) {
            users {
                id
                username
            }
            system (id: $id) {
                id
                maintenanceDueDate
                client {
                    id
                    fullName
                    telephone
                }
                address {
                    id
                    formatted
                    lat
                    lng
                }
            }
        }
    `, {
        variables: { id: systemId },
        onCompleted: data => {
            const { system } = data
            const { maintenanceDueDate } = system
            let newForm = { ...form, technicians: [me.id] }
            if (maintenanceDueDate) {
                const dueDateValue = moment(maintenanceDueDate);
                const now = moment();
                newForm = { ...newForm, datetime: now.isAfter(dueDateValue) ? `${now.format('YYYY-MM-DD')} 08:00` : `${maintenanceDueDate} 08:00` };
            }
            setForm({ ...newForm })
        },
    })

    const [createMaintenanceAppointment] = useMutation(gql`
        mutation CreateMaintenanceAppointmentMutation ( $data: MaintenanceAppointmentInput! ) {
            createMaintenanceAppointment ( data: $data ) {
                id
                datetime
                technicians {
                    id
                    username
                    firstName
                }
                address {
                    id
                    formatted
                    lat
                    lng
                    systems {
                        id
                        type
                    }
                }
                client {
                    id
                    fullName
                }
                systems {
                    id
                    type
                    dateInstallation
                    lastMaintenanceDate
                    config
                }
            }
        }
    `)

    if (loading) return <Loading />
    if (error) return 'error'

    const { system } = data
    const { client, address } = system

    return (
        <>
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${client.id}/view`, `${client.fullName}`],
                    [t`Nouveau RDV`]
                ]} />
            </Container>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <CustomCard>
                    <CardHeader title={t`Nouveau rendez-vous pour entretien`} />
                    <CardContent>
                        <Grid item xs={12}>
                            <Typography variant="h6">{client.fullName}</Typography>
                            <Typography sx={{ color: "text.disabled" }}>{address.formatted}</Typography>
                        </Grid>

                        <Box mt={4}>
                            <AppointmentForm
                                onChange={newValues => setForm(newValues)}
                                values={form}
                                telephone={client.telephone}
                                currentUser={me}
                            />
                        </Box>

                        <CardButtons>
                            <GoBackButton />
                            <SaveButton disabled={loading}
                                onClick={async () => {
                                    await createMaintenanceAppointment({
                                        variables: {
                                            data: form,
                                        }
                                    })
                                    enqueueSnackbar(t`RDV ajouté`, { variant: 'success' });
                                    history.push('/dispatching')
                                }}
                            />
                        </CardButtons>
                    </CardContent>
                </CustomCard>
            </Container>
        </>
    )
}


export const AddAppointmentComponent = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { params } = useRouteMatch();
    const { addressId } = params;
    const history = useHistory()
    const { me } = useMe()
    const [form, setForm] = useState({
        type: 'ESTIMATE',
        addressId: addressId,
        datetime: `${moment().format('YYYY-MM-DD')} 08:00`,
        notes: '',
        durationMinutes: 60,
        technicians: [me.id],
        smsReminder: { ...DEFAULT_SMS_REMINDER_VALUES },
        systems: []
    })
    const { data, loading } = useQuery(gql`
    query AddressQuery ( $id: ID! ) {
        address(id:$id){
            id
            formatted
            client {
              id
              fullName
              telephone
            }
            systems {
                id
                type
                identifier
            }
        }
    }`,
        {
            variables: { id: addressId },
            onCompleted: data => {
                if (data.address.systems.length === 1) {
                    setForm({ ...form, systems: [data.address.systems[0].id] })
                }
            }
        })

    const [createAppointment] = useMutation(gql`
        mutation CreateAppointmentMutation ( $data: AppointmentInput! ) {
            createAppointment ( data: $data ) {
                id
                datetime
                technicians {
                    id
                    username
                    firstName
                }
                address {
                    id
                    formatted
                    lat
                    lng
                    systems {
                        id
                        type
                    }
                }
                client {
                    id
                    fullName
                }

            }
        }
    `)

    if (loading) return 'loading...'
    const { address } = data
    const { systems } = address
    const { client } = address


    return (
        <>
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${client.id}/view`, `${client.fullName}`],
                    [t`Nouveau RDV`]
                ]} />
            </Container>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <CustomCard>
                    <CardHeader title={t`Nouveau rendez-vous`} />
                    <CardContent>
                        <Grid item xs={12}>
                            <Typography variant="h6">{client.fullName}</Typography>
                            <Typography sx={{ color: "text.disabled" }}>{address.formatted}</Typography>
                        </Grid>

                        <Box mt={4}>
                            <MyTextField
                                sx={{mb:2}}
                                label="Type"
                                value={form.type}
                                onChange={e => {
                                    setForm({ ...form, type: e.target.value });
                                }}
                                select
                            >
                                <MenuItem key={"Devis"} value={"ESTIMATE"}>{t`Devis`}</MenuItem>
                                <MenuItem key={"Install"} value={"INSTALLATION"}>{t`Installation`}</MenuItem>
                                {systems.length > 0 && <MenuItem key={"Entretien"} value={"MAINTENANCE"}>{t`Entretien`}</MenuItem>}
                            </MyTextField>
                            {form.type === "MAINTENANCE" &&
                                <MultiSelectSystems
                                    selectedSystemIds={form.systems}
                                    systems={systems}
                                    onChange={(newSystems) => setForm({ ...form, systems: newSystems })}
                                />
                            }
                            <AppointmentForm
                                onChange={newValues => setForm(newValues)}
                                values={form}
                                telephone={client.telephone}
                                currentUser={me}
                                type={form.type}
                            />
                        </Box>

                        <CardButtons>
                            <GoBackButton />
                            <SaveButton disabled={loading}
                                onClick={async () => {
                                    await createAppointment({
                                        variables: {
                                            data: form,
                                        }
                                    })
                                    enqueueSnackbar(t`RDV ajouté`, { variant: 'success' });
                                    history.push('/schedule')
                                }}
                            />
                        </CardButtons>
                    </CardContent>
                </CustomCard>
            </Container>
        </>
    )
}

export const APPOINTMENT_QUERY = gql`
    query AppointmentQuery ( $id: ID! ) {
        appointment ( id: $id ) {
            id
            type
            datetime
            durationMinutes
            notes
            smsReminder {
                id
                date
                time
                text
                number
            }
            technicians {
                id
                username
                firstName
            }
            systems {
                id
                type
                omnium
                identifier
                lastMaintenanceDate
            }
            client {
                id
                fullName
                telephone
            }
            address {
                id
                formatted
                lat
                lng
                geocodeStatus
                systems {
                    id
                    type
                    omnium
                }
            }
            createdAt
            createdBy {
                id
                username
                firstName
            }
        }
    }
`;

export const EditAppointmentComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { params } = useRouteMatch()
    const history = useHistory()
    const { id } = params
    const { me } = useMe()
    const [finishedLoading, setFinishedLoading] = useState(false)
    const { data, loading, error, refetch } = useQuery(
        APPOINTMENT_QUERY,
        {
            variables: { id },
            onCompleted: data => {
                const { appointment } = data
                const { datetime, durationMinutes, notes, technicians, systems, smsReminder } = appointment
                // remove the __typename attr from the smsReminder
                // because we're going to send it back as is in most cases.
                // @TODO there must be a better way.
                let reducedSmsReminder = {}
                if (smsReminder !== null) {
                    const { id, __typename, ...remainder } = smsReminder
                    reducedSmsReminder = remainder
                }
                const newForm = {
                    datetime,
                    durationMinutes,
                    notes,
                    technicians: technicians.map(t => t.id),
                    systems: systems.map(s => s.id),
                    smsReminder: (smsReminder === null) ? null : reducedSmsReminder,
                }
                setForm({ ...newForm })
                setFinishedLoading(true);
            },
        }
    )

    const [updateAppointment] = useMutation(gql`
        mutation UpdateMaintentanceAppointmentMutation ( $id: ID!, $data: MaintenanceAppointmentInput! ) {
            updateMaintenanceAppointment ( id: $id, data: $data ) {
                id
            }
        }
    `)

    const [form, setForm] = useState({ ...DEFAULT_APPOINTMENT_VALUES })

    if (loading) return <Loading />
    if (error) return 'error'
    const { appointment } = data;
    const { client } = appointment;
    return (
        <>
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${client.id}/view`, `${client.fullName}`],
                    [`/appointments/${id}`, `rdv du ${moment(appointment.datetime).format('LL')}`],
                    [t`Modifier`]
                ]} />
            </Container>
            <Container maxWidth="md">
                <CustomCard>
                    <CardHeader title={t`Modification de rendez-vous`} />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={12}>
                                {finishedLoading && (
                                    <AppointmentForm
                                        values={form}
                                        onChange={newValues => setForm(newValues)}
                                        telephone={client.telephone}
                                        currentUser={me}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <CardButtons>
                            <GoBackButton />
                            <SaveButton
                                onClick={async () => {
                                    await updateAppointment({
                                        variables: {
                                            id,
                                            data: form,
                                        }
                                    })
                                    await refetch();
                                    enqueueSnackbar(t`RDV modifié`, { variant: 'success' });
                                    history.push('/dispatching')
                                }}
                                disabled={loading}
                            >
                                {t`Sauvgarder`}
                            </SaveButton>
                        </CardButtons>
                    </CardContent>
                </CustomCard>
            </Container>
        </>
    )
}

export const ViewAppointmentComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { params } = useRouteMatch()
    const history = useHistory()
    const [open, showModal] = useState(false)
    const { id } = params
    const { data, loading, error } = useQuery(APPOINTMENT_QUERY, { variables: { id } })
    const [deleteAppointment] = useMutation(gql`
        mutation DeleteAppointmentMutation ( $id: ID ) {
            delete( appointmentId: $id ) {
                ok
            }
        }
    `)
    if (loading) return <Loading />
    if (error) return 'error'
    const { appointment } = data
    const { client, address, systems, technicians } = appointment
    return (
        <>
            <ConfirmDialog
                open={open}
                title={t`Annuler ce rendez-vous`}
                body={
                    t`Vous êtes sur le point de supprimer ce RDV. 
                     Veuillez être certain d'en avoir informé le client.\n
                     Cette action est définitive.
                     `}
                onClose={() => showModal(false)}
                onSubmit={async () => {
                    await deleteAppointment({ variables: { id } })
                    enqueueSnackbar(t`RDV supprimé`, { variant: 'success' });
                    history.replace(`/clients/${client.id}/view`)
                }}
            />

            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${client.id}/view`, `${client.fullName}`],
                    [t`Rendez-vous`]
                ]} />
            </Container>
            <Container maxWidth="md">
                <CustomCard>
                    <CardHeader
                        title={t`Rendez-vous le ${moment(appointment.datetime).format("LL")}`}
                        action={
                            <IconButton onClick={() => history.push(`/appointments/${appointment.id}/edit`)} color="inherit">
                                <EditIcon />
                            </IconButton>
                        }
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Typography variant="h4">{client.fullName}</Typography>
                                        <Typography variant="h6">{APPOINTMENT_TYPE_MAP[appointment.type]}</Typography>
                                        <Typography color="text.disabled">{address.formatted}</Typography>
                                        {address.geocodeStatus === "PASSED" && (
                                            <Box mt={2}>
                                                <Stack direction="row" spacing={2}>
                                                    {/**
                                                    <Button variant="outlined">Google Maps</Button>
                                                    <Button variant="outlined">Apple Maps</Button>
                                                    */}
                                                    <Box sx={{ '& a': { color: 'primary.main' } }}>
                                                        <Button
                                                            variant="outlined"
                                                            component="a"
                                                            href={`https://waze.com/ul?ll=${address.lat}%2C${address.lng}&q=${encodeURIComponent(address.formatted)}`}
                                                            endIcon={<NavigationIcon />}
                                                        >
                                                            {t`Ouvrir Waze`}
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} justifyContent="space-between">
                                        <Typography variant="h6">{moment(appointment.datetime).format('LL')}</Typography>
                                        <Typography>{t`à`} {moment(appointment.datetime).format('HH:mm')}</Typography>
                                        <Box mt={2}>
                                            <Button
                                                color="error"
                                                variant="contained"
                                                onClick={() => showModal(true)}
                                                startIcon={<DoDisturbSharpIcon />}>{t`Annuler ce rendez-vous`}</Button>
                                        </Box>
                                    </Grid>
                                </Grid>


                                <Box my={2}>
                                    <Typography variant="body2" color="text.disabled">{t`Technician`}</Typography>
                                    <Typography variant="body1">{technicians.map(t => t.firstName).join(', ')}</Typography>
                                </Box>
                                {["MAINTENANCE", "ASSISTANCE"].indexOf(appointment.type) > -1 &&
                                    <Stack direction="column">
                                        {systems.map(system => (
                                            <Box key={system.id}>
                                                <Box my={2}>
                                                    <Divider />
                                                </Box>
                                                <Stack direction="row" spacing={2}>
                                                    <Typography variant="body1">{prettyPrintSystem(system)}</Typography>
                                                    <Typography variant="body1">{t`dernier entretien`}: {valueOrNd(appointment.lastMaintenanceDate)}</Typography>
                                                </Stack>
                                            </Box>
                                        ))}
                                    </Stack>
                                }
                            </Grid>

                            {appointment.notes && (
                                <Grid item xs={12}>
                                    <Typography>{t`Notes`}: {appointment.notes}</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </CustomCard>

                <Box mt={2}>
                    <Typography variant="body2" color="text.disabled" align="right">
                        <Trans>Rendez-vous créé le {moment(appointment.createdAt).format('LLLL')} par {appointment.createdBy.firstName}</Trans>
                    </Typography>
                </Box>
            </Container>
        </>
    )
}
