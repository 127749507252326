import React, { useState, useRef, useEffect, useMemo } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import { useSnackbar } from 'notistack';


import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import { Trans, t } from '@lingui/macro'

import Box from '@mui/material/Box';
import {
    CustomCard,
    BasicBreadcrumbs,
    valueOrNd,
    MultiSelectUsers,
    toFrDate,
    ConfirmDialog,
    Loading,
    ZenDataGrid,
    useLocalStorage,
} from './Common'
import { frFR, enUS, nlNL } from '@mui/x-data-grid';

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import DesktopTimePicker from '@mui/lab/DesktopTimePicker';

import NavigationIcon from '@mui/icons-material/Navigation';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbSharpIcon from '@mui/icons-material/DoDisturbSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AddIcon from '@mui/icons-material/Add';


import {
    MenuItem,
    Button,
    IconButton,
    Stack,
    CardContent,
    CardHeader,
    Divider,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Autocomplete,
    Chip,
    Alert,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputAdornment,
    Paper,
} from '@mui/material'

import {
    MyTextField,
    GoBackButton,
    SaveButton,
    CardButtons,
    NarrowPageContainer,
} from './Common'

import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';


import moment from 'moment';

import { SelectTags } from './Tags'
import { useMe } from "../CurrentUserContext";


export const TaskCardContent = ({ task }) => {
    const history = useHistory()
    return (
        <Stack direction="row" sx={{ alignItems: 'center', cursor: 'pointer' }} spacing={2} onClick={() => history.push(`/tasks/${task.id}/view`)}>
            <AccessAlarmsIcon fontSize="large" />
            <Box p={1}>
                <Typography variant="body2" color="text.disabled">{
                    task.state === "TODO"
                        ? t`Tâche a completer`
                        : t`Tâche completée le ${toFrDate(task.doneAt)}`}
                </Typography>
                <Typography variant="body1">{task.description}</Typography>

                {task.tags.length > 0 && (
                    <Stack direction="row" spacing={1} py={1}>
                        {task.tags.map(tag => <Chip size="small" label={tag.name} variant="outlined" />)}
                    </Stack>
                )}

                {task.assignees.length > 0 ? (
                    <Stack direction="row" spacing={1}>
                        {task.assignees.map(assignee => assignee.firstName).join(', ')}
                    </Stack>
                ) : (
                    <Trans>Cette tâche n'est pas assignée!</Trans>
                )}
                <Typography variant="body2" color="text.disabled">
                    <Trans>Créé par {task.createdBy.firstName} le {toFrDate(task.createdAt)}</Trans>
                </Typography>
            </Box>
        </Stack>
    )
}

export const TaskCard = ({ task }) => (
    <Box sx={{ maxWidth: 300 }}>
        <TaskCardContent task={task}></TaskCardContent>
    </Box>
)


const MySearchableTasksComponent = () => {
    // @TODO this was copy&pasted from Clients.jsx. move everything to a hook or something
    // like, "useLocaleText()""
    const [lang] = useLocalStorage('lang', 'fr');
    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }
    const localeText = LOCALE_TEXT_MAP[lang]

    const history = useHistory()
    const [q, setQ] = useState('')
    const [state, setState] = useState('TODO')
    const [first, setFirst] = useState(10)
    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(0)
    const {data, error, loading} = useQuery(gql`
        query SearchTasksQuery ( $state: String!, $q: String!, $first: Int!, $skip: Int! ) {
            searchTasks ( state: $state, q: $q, first: $first, skip: $skip ) {
                count
                tasks {
                    id
                    state
                    description
                    createdAt
                    assignees {
                        id
                        firstName
                    }
                    createdBy {
                        id
                        firstName
                    }
                    client {
                        id
                        fullName
                        telephone
                        email
                        primaryAddress {
                            id
                            formatted
                        }
                    }
                }
            }
        }
    `, {
        variables: {
            state,
            q,
            first,
            skip,
        },
        fetchPolicy: 'no-cache',
    })

    const handleSizeChange = number => {
        setFirst(number)
    }
    const handlePageChange = number => {
        setSkip(number * first)
        setPage(number)
    }

    if (error) return 'error'
    const searchTasks = data?.searchTasks?.tasks || []
    const rowCount = data?.searchTasks?.count || 0
    return (
        <>
            <CustomCard>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <MyTextField
                                label={t`Recherche ...`}
                                value={q}
                                onChange={e => setQ(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <AccessAlarmsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <MyTextField
                                label={t`Etat de la tâche`}
                                value={state}
                                onChange={e => setState(e.target.value)}
                                select
                                >
                                <MenuItem value={'TODO'}>{t`A faire`}</MenuItem>
                                <MenuItem value={'DONE'}>{t`Achevee`}</MenuItem>
                                <MenuItem value={''}>{t`Tous`}</MenuItem>
                            </MyTextField>
                        </Grid>
                    </Grid>
                </CardContent>
            </CustomCard>
            
            {searchTasks.length === 0 && (
                <CustomCard>
                    <CardContent>
                        <Typography>{t`sans resultats`}</Typography>
                    </CardContent>
                </CustomCard>
            )}

            {searchTasks.length > 0 && (
                <Paper style={{ width: '100%' }}>
                    <ZenDataGrid
                        loading={loading}
                        rows={searchTasks}
                        columns={[
                            {
                                field: 'state',
                                headerName: t`Etat`,
                                sortable: false,
                                minWidth: 30,
                            },
                            {
                                field: 'createdAt',
                                headerName: t`Créé`,
                                sortable: false,
                                valueGetter: params => moment(params.row.createdAt).format('DD/MM/YYYY'),
                            },
                            {
                                field: 'description',
                                headerName: t`Description`,
                                sortable: false,
                                flex: 1,
                            },
                        ]}
                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        onRowClick={({ row }) => history.push(`/tasks/${row.id}/view`)}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handleSizeChange}
                        pageSize={first}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowCount={rowCount}
                        page={page}
                        isRowSelectable={() => true}
                        density="compact" // "compact" | "standard" | "comfortable"
                        autoHeight
                        hideFooterRowCount
                        localeText={localeText}
                        />
                </Paper>
            )}

            {/**
            {searchTasks.map(task => 
                <Accordion key={task.id} expanded={selected === task.id} onChange={(event, isExpanded) => setSelected(selected === task.id ? false : task.id)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{task.createdBy.firstName}</Typography>
                        <Typography color="text.disabled">xxx</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>moot</Typography>
                    </AccordionDetails>
                </Accordion>
            )}
            */}
        </>
    )
}


export const MyTasksComponent = () => {
    const { params } = useRouteMatch()
    const { id } = params
    const { data, loading, error } = useQuery(gql`
        query MyTasksQuery {
            tasks {
                id
                description
                state
                tags {
                    id
                    name
                }
                assignees {
                    id
                    username
                    firstName
                }
                createdAt
                createdBy {
                    id
                    username
                    firstName
                }
            }
        }
    `, {
        fetchPolicy: 'no-cache',
    })
    const history = useHistory();
    if (loading) return <Loading />
    if (error) return 'error'
    const { tasks } = data

    const now = moment()
    const todoTasks = tasks.filter(t => t.state === "TODO")
    const lateTasks = tasks.filter(t => t.dueDate && moment(t.dueDate) <= now && t.state === "TODO")
    const futureTasks = tasks.filter(t => t.dueDate && moment(t.dueDate) > now && t.state === "TODO")
    const limitlessTasks = tasks.filter(t => !Boolean(t.dueDate) && t.state !== "DONE")
    const doneTasks = tasks.filter(t => t.state === "DONE")

    return (
        <>
            <Box mb={2}>    
                <Button
                    variant="outlined"
                    onClick={() => history.push(`/tasks/add`)}
                    startIcon={<AddIcon />}
                    >
                    {t`Ajouter une tâche`}
                </Button>
            </Box>
            <MySearchableTasksComponent />
        </>
    )
}

export const SelectClient = ({ selected, onSelected, autoFocus }) => {
    const [search, setSearch] = useState('')
    const { data, error } = useQuery(gql`
        query SearchClientsQuery ( $search: String ) {
            searchClients ( search: $search, first: 5, skip: 0 ) {
                clients {
                    id
                    fullName
                }
            }
        }
    `, {
        variables: {
            search,
        }
    })
    if (error) return 'error'
    const clients = data?.searchClients?.clients || []
    const options = clients.map(client => ({
        id: client.id,
        label: `${client.fullName}`,
    }))
    return (
        <>
            <Grid item xs={12}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={options}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValue) => {
                        onSelected(newValue?.id)
                    }}
                    // sx={{ width: 300 }}
                    renderInput={(params) =>
                        <MyTextField {...params}
                            label={t`Client`}
                            autoFocus={autoFocus}
                            placeholder={t`Rechercher un client ...`}
                            onChange={e => setSearch(e.target.value)}
                        />
                    }
                />
            </Grid>
        </>
    )
}

const DEFAULT_TASK = {
    description: "",
    tags: [],
    client: null,
    assignees: [],
    dueDate: null,
}

const CreateTaskForm = ({ form, canSelectClient, onChange }) => {
    return (
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            {canSelectClient && (
                <Grid item xs={12}>
                    <SelectClient autoFocus selected={form.client} onSelected={clientId => onChange({ ...form, client: clientId })} />
                </Grid>
            )}
            <MyTextField
                label={t`Description de la tâche`}
                placeholder={t`Description de la tâche` + `...`}
                multiline
                autoFocus={!canSelectClient}
                value={form.description}
                onChange={e => onChange({ ...form, description: e.target.value })}
                rows={7}
            />
            <SelectTags
                tags={form.tags}
                onChange={tags => onChange({ ...form, tags })}
            />
            <MultiSelectUsers
                label={t`Assigner cette tâche`}
                selectedUserIds={form.assignees}
                onChange={assignees => onChange({ ...form, assignees })}
            />
            <DesktopDatePicker
                label={t`Date limite`}
                inputFormat="yyyy-MM-DD"
                value={form.dueDate}
                mask="____-__-__"
                onChange={dueDate => {
                    onChange({ ...form, dueDate: dueDate.format('YYYY-MM-DD') })
                }}
                renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
            />
        </Stack>
    )
}

const ADD_TASK_MUTATION = gql`
mutation AddTaskMutation ( $data: TaskInput! ) {
    addTask( data: $data ) {
        id
        client {
            id
            fullName
        }
        assignees {
            id
            username
            firstName
        }
        description
        dueDate
        createdBy {
            id
            username
            firstName
        }
        createdAt
    }
}
`

export const AddTaskComponent = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory();
    const [data, setForm] = useState({ ...DEFAULT_TASK })
    const [addTask] = useMutation(ADD_TASK_MUTATION)
    const { refetchMe } = useMe()
    return (
        <>
            <NarrowPageContainer>
                <CustomCard>
                    <CardHeader title={t`Nouvelle tâche`} />
                    <CardContent>
                        <CreateTaskForm
                            canSelectClient={true}
                            form={data}
                            onChange={newForm => setForm(newForm)}
                        />
                        <CardButtons>
                            <GoBackButton />
                            <SaveButton
                                onClick={async () => {
                                    // await addTask({ variables: { data }})
                                    const { data: addTaskData } = await addTask({ variables: { data } })
                                    await refetchMe();
                                    enqueueSnackbar(t`Tâche ajoutée`, { variant: 'success' });
                                    history.replace(`/tasks/${addTaskData.addTask.id}/view`)
                                }}
                            />
                        </CardButtons>
                    </CardContent>
                </CustomCard>
            </NarrowPageContainer>
        </>
    )
}

export const AddClientTaskComponent = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const history = useHistory()
    const { params } = useRouteMatch()
    const { id } = params
    const { data: queryData, error, loading } = useQuery(gql`
        query ClientQuery ( $id: ID! ) {
            client ( id: $id ) {
                id
                fullName
                telephone
            }
        }
    `, { variables: { id } })
    const [form, setForm] = useState({ ...DEFAULT_TASK, client: id })
    const [addTask] = useMutation(ADD_TASK_MUTATION)
    if (loading) return <Loading />
    if (error) return 'error'
    const { client } = queryData;
    return (
        <>
            <NarrowPageContainer>
                <CustomCard>
                    <CardHeader title={t`Nouvelle tâche`} />
                    <CardContent>
                        <Box mb={2}>
                            <Typography variant="body2" color="text.disabled">{t`Client`}:</Typography>
                            <Typography variant="h6">{client.fullName}</Typography>
                            <Typography>{t`Tel.`} {valueOrNd(client.telephone)}</Typography>
                        </Box>
                        <CreateTaskForm
                            canSelectClient={false}
                            form={form}
                            onChange={newForm => setForm(newForm)}
                        />
                        <CardButtons>
                            <GoBackButton />
                            <SaveButton
                                onClick={async () => {
                                    const { data } = await addTask({ variables: { data: form } })
                                    enqueueSnackbar(t`Tâche ajoutée`, { variant: 'success' });
                                    history.replace(`/tasks/${data.addTask.id}/edit`)
                                }}
                            />
                        </CardButtons>
                    </CardContent>
                </CustomCard>
            </NarrowPageContainer>
        </>
    )
}


const TASK_QUERY = gql`
query TaskQuery ( $id: ID! ) {
    task ( id: $id ) {
        id
        dueDate
        description
        assignees {
            id
            username
        }
        client {
            id
            fullName
            telephone
        }
        tags {
            id
            name
        }
        state
        doneAt
        createdBy {
            id
            username
            firstName
        }
        doneBy {
            id
            username
            firstName
        }
    }
}
`

export const EditTaskComponent = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { params } = useRouteMatch()
    const { id } = params
    const [form, setForm] = useState({ ...DEFAULT_TASK })
    const { data, loading, error, refetch } = useQuery(TASK_QUERY, {
        variables: { id },
        onCompleted: data => {
            const { task } = data
            setForm({
                ...form,
                description: task.description,
                tags: task.tags.map(t => t.name),
                client: task.client?.id,
                assignees: task.assignees.map(a => a.id),
                dueDate: task.dueDate ? toFrDate(task.dueDate) : '',
            })
        },
    })

    const [editTask] = useMutation(gql`
        mutation EditTaskMutation ( $id: ID!, $data: TaskInput! ) {
            editTask ( id: $id, data: $data ) {
                id
            }
        }
    `)

    if (loading) return <Loading />
    if (error) return 'error'
    const { task } = data
    const { client } = task
    return (
        <NarrowPageContainer>
            <CustomCard>
                <CardHeader title={t`Modifier tâche`} />
                <CardContent>
                    {form.client && (
                        <Box mb={2}>
                            <Typography variant="body2" color="text.disabled">{t`Client`}:</Typography>
                            <Typography variant="h6">{client.fullName}</Typography>
                            <Typography>{t`Tel.`} {valueOrNd(client.telephone)}</Typography>
                        </Box>
                    )}
                    <CreateTaskForm
                        canSelectClient={false}
                        form={form}
                        onChange={newForm => setForm(newForm)}
                    />
                    <CardButtons>
                        <GoBackButton />
                        <SaveButton
                            onClick={async () => {
                                await editTask({ variables: { id, data: form } });
                                await refetch();
                                enqueueSnackbar(t`Tâche modifiée`, { variant: 'success' });
                            }}
                            disabled={loading}
                        />
                    </CardButtons>
                </CardContent>
            </CustomCard>
        </NarrowPageContainer>
    )
}

export const ViewTaskComponent = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { params } = useRouteMatch()
    const history = useHistory()
    const { id } = params
    const [open, setOpen] = useState(false)
    const { data, loading, error } = useQuery(TASK_QUERY, { variables: { id } })
    const [DeleteTask] = useMutation(gql`
    mutation Delete($taskId:ID){
        delete(taskId:$taskId){
          ok
        }
      }
    `)
    const { refetchMe } = useMe()
    const [markTaskAsDone] = useMutation(gql`
        mutation MarkTaskAsDone ( $id: ID! ) {
            markTaskAsDone ( id: $id ) {
                id
                state
                doneAt
                doneBy {
                    id
                    username
                    firstName
                }
            }
        }
    `)
    const [markTaskAsTodo] = useMutation(gql`
        mutation MarkTaskAsTodo ( $id: ID! ) {
            markTaskAsTodo ( id: $id ) {
                id
                state
            }
        }
    `)
    if (loading) return <Loading />
    if (error) return 'error'
    const { task } = data
    
    const title = task.state === "TODO"
        ? t`Tâche a completer`
        : t`Tâche completée le ${toFrDate(task.doneAt)}`

    const handleDeleteTask = async () => {
        setOpen(false)
        await DeleteTask({variables:{taskId: task.id}})
        await refetchMe()
        history.goBack()

    }

    return (
        <NarrowPageContainer>
            <CustomCard>
                <CardHeader title={title} />
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        {task.state === "DONE" && (
                            <Box p={2} sx={{ backgroundColor: 'success.main' }}>
                                <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography color="success.contrastText" variant="h5">{t`Achevée`}</Typography>
                                    <Box>
                                        <Typography color="success.contrastText"><Trans>fait par {task.doneBy.firstName}</Trans></Typography>
                                        <Typography color="success.contrastText"><Trans>le {moment(task.doneAt).format('LLL')}</Trans></Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        )}

                        <Grid container>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Typography variant="body2" color="text.disabled">{t`Description`}</Typography>
                                    <Typography variant="body1">{task.description}</Typography>
                                    {task.tags.length > 0 && (
                                        <Box py={1}>
                                            <Stack direction="row" spacing={1}>
                                                {task.tags.map(tag => <Chip label={tag.name} variant="outlined" />)}
                                            </Stack>
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                {task.dueDate && (
                                    <Box>
                                        <Typography variant="body2" color="text.disabled">{t`Date limite`}</Typography>
                                        <Typography variant="body1">{task.dueDate}</Typography>
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </Stack>
                    <CardButtons>
                        <Box sx={{ marginRight: "auto" }}>
                            <Button color="error" onClick={() => setOpen(true)}>
                                {t`Supprimer`}
                            </Button>
                        </Box>
                        <GoBackButton />
                        {task.state === "TODO" && (
                            <SaveButton
                                onClick={async () => {
                                    await markTaskAsDone({ variables: { id } })
                                    await refetchMe();
                                    // await refetch();
                                    enqueueSnackbar(t`Tâche complétée`, { variant: 'success' });
                                }}
                                disabled={loading}
                            >
                                {t`c'est fait!`}
                            </SaveButton>
                        )}
                    </CardButtons>
                </CardContent>
            </CustomCard>
            <Typography align="right" variant="body2" color="text.disabled">
                <Trans>Tâche créé le {moment(task.createdAt).format('LLL')} par {task.createdBy.firstName}</Trans>
            </Typography>
            <ConfirmDialog 
                open={open}
                onClose={() => setOpen(false)}
                title={t`Supprimer tâche`}
                body={<Trans>Veuillez confirmer l'action de suppression.</Trans>
                }
                onSubmit={() => handleDeleteTask()}
            />
        </NarrowPageContainer>
    )
}
