import React, { useEffect, useState } from 'react'
import {
  Redirect,
  useParams,
} from 'react-router-dom';
import {
  Stack,
  Button,
  Typography,
  Divider,
  Paper,
} from '@mui/material'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Settings } from './Settings'
import {
  Loading, MyTextField,
} from './components/Common'

export const AuthenticationRequired = ({ children }) => {
  const [verifyToken] = useMutation(gql`
    mutation VerifyToken ( $token: String! ) {
      verifyToken(token: $token) {
        payload
      }
    }
  `)

  const [cycleAccessToken] = useMutation(gql`
    mutation CycleAccessToken {
      cycleAccessToken {
        token
      }
    }`)

  const [showLoginForm, setShowLoginForm] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    const updateAccessTokenFunc = async () => {
      let response;
      try {
        response = await cycleAccessToken();
        localStorage.setItem(Settings.ACCESS_TOKEN, response.data.cycleAccessToken.token)
      } catch (e) {
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        setShowLoginForm(true)
      }
    }
    const interval = setInterval(() => {
      if (localStorage.getItem(Settings.ACCESS_TOKEN)) {
        updateAccessTokenFunc()
      }
    }, 1000 * 60) // every minute 
    updateAccessTokenFunc();
    return () => clearInterval(interval)
  }, [cycleAccessToken])

  useEffect(() => {
    const token = localStorage.getItem(Settings.ACCESS_TOKEN)
    if (!token) {
      setShowLoginForm(true);
      return;
    }
    (async function useEffectInner() {
      let response
      try {
        response = await verifyToken({ variables: { token } })
        setInitialLoading(false)
      } catch (e) {
        setShowLoginForm(true)
        setInitialLoading(false)
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        return
      }
      if (response.errors && response.errors.length > 0) {
        setShowLoginForm(true)
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        return
      }
      setShowLoginForm(false);
    })()
  }, [verifyToken])

  if (showLoginForm) return <LoginScreen />
  if (initialLoading) return <Loading />
  return children
}

export const AuthHandlerComponent = () => {
  const { token } = useParams()
  localStorage.setItem(Settings.ACCESS_TOKEN, token);
  return <Redirect to={sessionStorage.getItem("loginUrl") === "/" ? Settings.HOME_PAGE : sessionStorage.getItem("loginUrl")} />
}
  
export const LoginScreen = () => {
  sessionStorage.setItem("loginUrl", window.location.pathname)
  const [tokenAuth] = useMutation(gql`
    mutation TokenAuthMutation ( $username: String!, $password: String! ) {
      tokenAuth ( username: $username, password: $password ) {
        token
      }
    }
  `)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper sx={{ padding: 2, boxShadow: '5px 5px 10px rgba(0,0,0,.5)' }}>
        <Typography variant="h4" textAlign="center" mb={3}>AUTHENTICATION REQUIRED</Typography>
        <Stack direction="row" spacing={2}>
          <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
            <MyTextField
              label="Email"
              onChange={e => setUsername(e.target.value)}
              />
            <MyTextField
              label="Password"
              type="password"
              onChange={e => setPassword(e.target.value)}
              />
            <Button variant="outlined" onClick={async () => {
              try {
                const { data } = await tokenAuth({variables: {username, password}})
                // const { token } = tokenAuth
                // window.location.href = `/auth/${token}`
                window.location.href = `/auth/${data.tokenAuth.token}`
              } catch {

              }
            }}>login</Button>
          </Stack>
          <Divider orientation="vertical" flexItem/>
          <Stack spacing={3} direction="column" alignItems="center" justifyContent="center">
            <Button size="large" variant="outlined"
              onClick={() => {
                window.location.assign(Settings.LOGIN_URL)
              }}>
                authenticate with google
            </Button>
          </Stack>
        </Stack>
      </Paper>
      {/**
      <Stack direction="row" justifyContent="space-around">
        <div>fr</div>
        <div>en</div>
        <div>nl</div>
      </Stack>
      */}
    </div>
  )
}
