import React, { useState, useRef, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";

import { Redirect, useHistory, useRouteMatch } from 'react-router-dom'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
    CustomCard,
    BasicBreadcrumbs,
    valueOrNd,
    MultiSelectUsers,
} from './Common'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import DesktopTimePicker from '@mui/lab/DesktopTimePicker';

import NavigationIcon from '@mui/icons-material/Navigation';
import EditIcon from '@mui/icons-material/Edit';
import DoDisturbSharpIcon from '@mui/icons-material/DoDisturbSharp';
import { t } from '@lingui/macro'

import {
    MenuItem,
    Button,
    IconButton,
    Stack,
    CardContent,
    CardHeader,
    Divider,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Autocomplete,
    Chip,
} from '@mui/material'

import {
    MyTextField,
} from './Common'

import moment from 'moment';


export const SelectTags = ({ tags, onChange }) => {
    tags = tags || []
    onChange = onChange || (() => {})
    const [search, setSearch] = useState("")
    const { data, error } = useQuery(gql`
        query SearchTagsQuery ( $search: String! ) {
            searchTags ( q: $search ) {
                id
                name
            }
        }
    `, { variables: { search }})
    if (error) return 'error'
    const searchTags = data?.searchTags || [];
    const options = searchTags.map(t => t.name)
    return (
        <Autocomplete
          multiple
          options={options}
          value={tags}
          getOptionLabel={option => option}
          freeSolo
          onChange={(event, newValue) => {
            onChange(newValue)
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return <Chip {...getTagProps({ index })} key={index} variant="outlined" label={option} />
            })
          }
          renderInput={(params) => (
            <MyTextField
              {...params}
              onChange={e => setSearch(e.target.value)}
              label={t`Categories`}
              placeholder="..."
            />
          )}
        />
    )
}
