import * as React from 'react';
import { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ConstructionIcon from '@mui/icons-material/Construction';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import PeopleIcon from '@mui/icons-material/People';
import EuroIcon from '@mui/icons-material/Euro';
import ListItem from '@mui/material/ListItem';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
  Menu,
  MenuItem,
  Stack,
  Badge,
  Button,
} from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import GradingIcon from '@mui/icons-material/Grading';
import EventIcon from '@mui/icons-material/Event';
import LogoutIcon from '@mui/icons-material/Logout';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Link, useLocation } from 'react-router-dom';

import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';

import { Settings } from '../Settings.js'
import { useLocalStorage } from './Common.jsx';
import { t } from '@lingui/macro'
import { useMe } from '../CurrentUserContext.jsx';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


const ListItemLink = props => {
  const [selected, setSelected] = useState(false)
  const location = useLocation()
  const { to } = props
  useEffect(() => {
    setSelected(location.pathname.startsWith(to))
  }, [location, to])
  return (
    <ListItemButton
      component={Link}
      to={to}
      selected={selected}
      {...props}
    />
  )
}


// const AccountMenu = () => {
//   const [anchorEl, setAnchorEl] = useState(null)
//   // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
//   const isMenuOpen = Boolean(anchorEl)
//   // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
//   const openProfileMenu = event => setAnchorEl(event.currentTarget)
//   const closeMenu = () => {
//     setAnchorEl(null)
//     // setMobileMoreAnchorEl(null);
//   }
//   const menuId = 'primary-search-account-menu'
// 
//   return
// }


const LanguageChoiceDropdown = ({ selectedLang, setLang }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <>
      <Button sx={{ color: 'text.primary' }}
        onClick={e => {
          setAnchorEl(e.currentTarget)
        }}>
        ▼ {selectedLang.toUpperCase()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => setLang('en')}>EN</MenuItem>
        <MenuItem onClick={() => setLang('fr')}>FR</MenuItem>
        <MenuItem onClick={() => setLang('nl')}>NL</MenuItem>
      </Menu>
    </>
  )
}


export default function PersistentDrawerLeft(props) {
  const { toggleTheme, lang, setLang } = props
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = useLocalStorage(false);
  const { me } = useMe()
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)
  const [anchorEl, setAnchorEl] = useState(null)
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const openProfileMenu = event => setAnchorEl(event.currentTarget)
  const closeMenu = () => {
    setAnchorEl(null)
    // setMobileMoreAnchorEl(null);
  }
  const menuId = 'primary-search-account-menu'

  const AccountMenu = () => (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={closeMenu}
    >
      <MenuItem onClick={() => {
        toggleTheme()
        setAnchorEl(null)
      }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', flexGrow: 1, minWidth: 180 }}>
          {t`Mode sombre`}
          {theme.palette.mode === 'dark' ? <ToggleOnOutlinedIcon /> : <ToggleOffOutlinedIcon />}
        </Stack>
      </MenuItem>

      <Divider />

      <MenuItem onClick={() => {
        localStorage.removeItem(Settings.ACCESS_TOKEN)
        window.location.reload()
      }}>
        {t`Déconnexion`}
      </MenuItem>
    </Menu>
  )

  return (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                alignItems: 'center',
                flexGrow: 1
              }}
            >
              <Box>
                <Typography variant="h6" noWrap component="div">
                  INTRANET | {me.profile.organization.name.toUpperCase()}
                </Typography>
              </Box>
              {/**
              <MyTextField size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }} />
              */}
            </Stack>
            <LanguageChoiceDropdown selectedLang={lang} setLang={setLang} />
            <IconButton
              size="large"
              edge="end"
              onClick={openProfileMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>

      <AccountMenu />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />

        <List>
          <ListItemLink to="/clients">
            <ListItemIcon><PeopleIcon /></ListItemIcon>
            <ListItemText primary={t`Mes clients`} />
          </ListItemLink>

          <ListItemLink to="/dispatching">
            <ListItemIcon><ConstructionIcon /></ListItemIcon>
            <ListItemText primary={t`Entretiens`} />
          </ListItemLink>

          {(me.profile.organization.id === "1") && (
            <ListItemLink to="/gse/workorders">
              <ListItemIcon><GradingIcon /></ListItemIcon>
              <ListItemText primary={`Fiches de travail`} />
            </ListItemLink>
          )}

          <ListItemLink to="/schedule">
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary={t`Mon planning`} />
          </ListItemLink>

          <ListItemLink to="/tasks">
            <ListItemIcon>
              <Badge badgeContent={me.profile.numTasksPending} color="secondary">
                <FormatListBulletedIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={t`Mes tâches`} />
          </ListItemLink>
          {/**
          <ListItemLink to="/calendar">
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary="Calendrier" />
          </ListItemLink>
          <ListItemLink to="/tasks/add">
            <ListItemIcon><EventIcon /></ListItemIcon>
            <ListItemText primary="Nouvelle tâche" />
          </ListItemLink>
          */}
          <ListItemLink
            to="/invoices"
            disabled={true}
            sx={{
              cursor: 'not-allowed',
            }}
          >
            <ListItemIcon><EuroIcon /></ListItemIcon>
            <ListItemText primary={t`Facturation`} />
          </ListItemLink>

          
          {me.profile.mayExportData && (
            <ListItemLink to='/exportxlsx'>
              <ListItemIcon><FileDownloadOutlinedIcon /></ListItemIcon>
              <ListItemText primary={t`Export`} />
            </ListItemLink>
          )}
          <ListItemLink
            to="/reporting"
            disabled={true}
            sx={{
              cursor: 'not-allowed',
            }}
          >
            <ListItemIcon><BarChartIcon /></ListItemIcon>
            <ListItemText primary={t`Reporting`} />
          </ListItemLink>

          <Divider />
          {me.profile.maySeeDemos && (
            <ListItemLink to="/demo">
              <ListItemIcon><InboxIcon /></ListItemIcon>
              <ListItemText primary="Demos" />
            </ListItemLink>
          )}
          {me.profile.maySeePlayground && (
            <ListItem button component="a" target="_blank" href={`/playground.html?endpoint=${Settings.GRAPHQL_URI}?headers=${encodeURIComponent(JSON.stringify({ Authorization: "Bearer " + localStorage.getItem(Settings.ACCESS_TOKEN) }))}`} >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Playground" />
            </ListItem>
          )}
          {(me.profile.maySeeDemos || me.profile.maySeePlayground) && (
            <Divider />
          )}
          {/**
          <ListItem button onClick={() => toggleTheme()}>
            <ListItemIcon>
              {theme.palette.mode === 'dark'
                ? <ToggleOnOutlinedIcon />
                : <ToggleOffOutlinedIcon /> 
                }
            </ListItemIcon>
            <ListItemText>{t`Mode sombre`}</ListItemText>
          </ListItem>
          <Divider />
          */}
          <ListItem button onClick={() => {
            localStorage.removeItem(Settings.ACCESS_TOKEN)
            window.location.reload()
          }}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText>{t`Déconnexion`}</ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {/**
        <pre>{JSON.stringify(me, null, 2)}</pre>
         */}
        {props.children}
      </Main>
    </Box>
  );
}
