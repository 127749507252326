import React, { useState } from "react";

import { useRouteMatch, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from "@apollo/client";
import gql from 'graphql-tag';
import { CLIENT_QUERY } from "../Queries";
import { useSnackbar } from 'notistack';

import 'moment/locale/fr'
import {
    CustomCard,
    Comments,
    DocumentUpload,
    BasicBreadcrumbs,
    valueOrNd,
    Loading,
    useCurrentScreenSize,
    IconBarComponent,
    // getFileExtension,
} from './Common'

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Trans, t, Plural } from '@lingui/macro'

import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import {
    Box,
    Divider,
    Paper,
    // Table,
    // TableHead,
    // TableRow,
    // TableCell,
    // TableBody,
} from '@mui/material'

// import { Settings } from '../Settings'

import PersonIcon from '@mui/icons-material/Person';
import LaunchIcon from '@mui/icons-material/Launch';
import BusinessIcon from '@mui/icons-material/Business';
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
// import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Button from '@mui/material/Button';

import {
    AddressMapComponent,
} from './Demos'
import { useMe } from "../CurrentUserContext";

// import {
//     Link,
// } from "react-router-dom";


const ClientPrimaryAddressMap = ({ client }) => {
    const { primaryAddress } = client;
    return (
        <CustomCard>
            <CardHeader title={t`Carte`} />
            <CardContent sx={{ height: '20rem' }}>
                {(primaryAddress.lat && primaryAddress.lng) ? (
                    <AddressMapComponent
                        fullScreen={true}
                        addresses={[primaryAddress]}
                        center={primaryAddress}
                    />
                ) : (
                    <>{t`Cette adress n'est pas encore geocodee`}</>
                )}
            </CardContent>
        </CustomCard>
    )
}


// const ClientMutationLogsTable = ({ id }) => {
//     const { data, loading, error } = useQuery(gql`
//         query ClientMutationLogsQuery ( $id: ID! ) {
//             clientMutationLogs ( id: $id ) {
//                 id
//                 createdBy {
//                     id
//                     username
//                 }
//                 createdAt
//                 mutationName
//                 raisedException
//                 args
//                 kwargs
//             }
//         }
//     `, { variables: { id } })
//     if (loading) return 'loading...'
//     if (error) return 'error'
//     const { clientMutationLogs } = data
//     if (clientMutationLogs.length === 0) {
//         return <></>
//     }
//     return (<>
//         <CustomCard>
//             <CardHeader title={t`Change logs`} />
//             <CardContent>
//                 <Table>
//                     <TableBody>
//                         {clientMutationLogs.map((log, i) => (
//                             <TableRow>
//                                 <TableCell>{moment(log.createdAt).format('LLLL')}</TableCell>
//                                 <TableCell>{log.createdBy.username}</TableCell>
//                                 <TableCell>
//                                     {(log.mutationName === "UpdateClientMutation.mutate") && (<>updated a clients profile</>)}
//                                     {(log.mutationName === "UpdateSystemConfigMutation.mutate") && (<>updated a systems configuration</>)}
//                                     {(log.mutationName === "CreateSystemMutation.mutate") && (<>created a new system</>)}
//                                     {(log.mutationName === "DocumentUploadMutation.mutate") && (<>uploaded document</>)}
//                                     {(log.mutationName === "CreateAppointmentMutation.mutate") && (<>created appointment</>)}
//                                     {(log.mutationName === "CreateClientCommentMutation.mutate") && (<>added a comment</>)}
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </CardContent>
//         </CustomCard>
//     </>)
// }

const AddressSystemsTable = ({ systems, clientId, addressFormatted, addressId }) => {
    const history = useHistory();
    const screenSize = useCurrentScreenSize()
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                    <Box xs={12} sm={5}>
                        <Typography variant="h6">
                            {addressFormatted}
                        </Typography>
                        <Typography color="text.primary">
                            <Plural
                                value={systems.length}
                                _0="Aucun systeme"
                                _1="1 systeme"
                                other="# systemes" />
                        </Typography>
                        <Stack
                            direction={screenSize.dynamicWidth > 676 ? "row" : "column"}
                            spacing={screenSize.dynamicWidth > 676 ? 1 : 0}
                        >
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    variant="outlined"
                                    onClick={() => history.push(`/clients/${clientId}/addsystem/${addressId}`)}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    {t`ajouter un systeme`}
                                </Button>
                            </Box>
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    variant="outlined"
                                    onClick={() => history.push(`/clients/${clientId}/addappointment/${addressId}`)}
                                    startIcon={<AddCircleOutlineIcon />}
                                >
                                    {t`ajouter un rdv`}
                                </Button>
                            </Box>
                            <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                <Button
                                    fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                    onClick={() => history.push(`/clients/${clientId}/editaddress/${addressId}`)}
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    variant="outlined">
                                    {t`Modifier`}
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    {systems.map(system => (
                        <Paper
                            variant="outlined"
                            style={{ width: '100%', cursor: 'pointer', marginBottom: '1rem' }}
                            sx={{
                                ':hover': {
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                }
                            }}
                            onClick={() => history.push(`/systems/${system.id}/view`)}
                        >
                            <Box p={2}>
                                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                                    <Typography variant="h6">
                                        <span style={{ textTransform: "uppercase" }}>
                                            {system.type} {system.identifier ? `- ${system.identifier.toUpperCase()}` : ""}
                                        </span>
                                    </Typography>
                                    <Typography variant="h6">
                                        <LaunchIcon />
                                    </Typography>
                                </Stack>
                                <Typography>

                                </Typography>

                                <IconBarComponent icons={system.icons} />

                                <Typography sx={{ color: "text.primary" }}
                                >
                                    <Trans>Installé le {system.dateInstallation}</Trans>
                                    
                                    {system.installers.length 
                                        ? <> <Trans>par</Trans> {system.installers.map(i => i.firstName).join(" ")}</>
                                        : <></>
                                        }
                                    
                                </Typography>
                                <Typography sx={{ color: "text.primary" }}
                                >
                                    <Trans>Date limite</Trans>: {valueOrNd(system.maintenanceDueDate)}
                                </Typography>
                            </Box>
                        </Paper>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}


// const ClientDocuments = ({ documents }) => {
//     const [open, setOpen] = useState(false)
//     const [docId, setDocId] = useState()
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//     const history = useHistory()
// 
//     return (
//         <>
//         <Table size="small">
//             <TableHead>
//                 <TableRow>
//                     <TableCell>{t`Titre`}</TableCell>
//                     <TableCell>{t`Date upload`}</TableCell>
//                     <TableCell ></TableCell>
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 {documents.map((doc) => (
//                     <TableRow
//                         key={doc.id}
//                         onClick={() => {
//                             history.push(`/document/${doc.id}`)
//                         }}
//                     >
//                         <TableCell >{doc.uploadedFilename || doc.titre || 'sans titre'}</TableCell>
//                         <TableCell>{doc.dateUpload}</TableCell>
//                         <TableCell align="right">
//                             <Stack direction="row" m={-1}>
//                                 <Link to={{ pathname: `${Settings.MEDIA_URL}/${doc.file}` }} target="_blank">
//                                     <IconButton  >
//                                         {(getFileExtension(doc.file) == "pdf") ?
//                                             <PictureAsPdfIcon sx={{ color: "text.primary" }} /> :
//                                             (["jpg", "jpeg", "png"].includes(getFileExtension(doc.file))) ?
//                                                 <ImageOutlinedIcon sx={{ color: "text.primary" }} /> :
//                                                 <InsertDriveFileOutlinedIcon sx={{ color: "text.primary" }} />
//                                         }
//                                     </IconButton>
//                                 </Link>
//                                 <IconButton color="inherit" onClick={() => {
//                                     setDocId(doc.id)
//                                     setOpen(true)
//                                 }}>
//                                     <DeleteOutlineIcon />
//                                 </IconButton>
//                             </Stack>
//                         </TableCell>
//                     </TableRow>
//                 ))}
//             </TableBody>
//         </Table>
//         </>
//     )
//     
// }

const ClientDetails = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { me } = useMe();

    const history = useHistory()
    const { params } = useRouteMatch();

    const { id } = params;

    const { data, loading, refetch } = useQuery(CLIENT_QUERY, { variables: { id } })

    const [ createGseWorkOrder ] = useMutation(gql`
    mutation CreateGseWorkOrderMutation ( $clientId: ID! ) {
        createGseWorkOrder ( clientId: $clientId ) {
            id
        }
    }
    `)

    const [updateClientComment] = useMutation(gql`
    mutation UpdateComment($id:ID!, $text:String!){
        updateClientComment(id:$id, text:$text){
          id
        }
      }
    `)

    const [deleteClientComment] = useMutation(gql`
    mutation Delete($clientCommentId:ID){
        delete(clientCommentId:$clientCommentId){
          ok
        }
      }
    `)
    const [deleteDoc] = useMutation(gql`
    mutation Delete($docId:ID){
        delete(docId:$docId){
          ok
        }
      }
    `)
    const [addComment] = useMutation(gql`
    mutation ClientComment($clientId:ID!, $text:String!){
        createClientComment(clientId:$clientId, text:$text){
          id
        }
      }`)
    const [addDocument] = useMutation(gql`
    mutation addDocument($clientId:ID!, $file:Upload!){
        docUpload(clientId:$clientId, file:$file){
          id
        }
      }`)
    const [comment, setComment] = useState("")
    const screenSize = useCurrentScreenSize()

    if (loading) return <Loading />

    const { client } = data
    const {
        // appointments,
        primaryAddress,
        billingAddress,
        documents,
        addresses,
    } = client

    const handleCommentChange = (e) => {
        setComment(e.target.value)
    }

    const handlePostComment = async () => {
        await addComment({
            variables: {
                clientId: id,
                text: comment,
            }
        })
        await refetch()
        enqueueSnackbar(t`Commentaire ajouté`, { variant: 'success' });
        setComment("")
    }

    const handleFileChange = async (e) => {
        await addDocument({
            variables: {
                file: e.target.files[0],
                clientId: id,
            }
        })
        await refetch()
        enqueueSnackbar(t`Document ajouté`, { variant: 'success' });
    }

    const handleUpdateComment = async (comment) => {
        await updateClientComment({ variables: comment })
        await refetch()
        enqueueSnackbar(t`Commentaire modifié`, { variant: 'success' });
    }

    const handleDeleteComment = async (id) => {
        await deleteClientComment({ variables: { clientCommentId: id } })
        await refetch()
        enqueueSnackbar(t`Commentaire supprimé`, { variant: 'success' });
    }
    const handleDeleteDoc = async (id) => {
        await deleteDoc({ variables: { docId: id } })
        await refetch()
    }

    return <>
        <Container maxWidth="lg" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`${client.fullName}`]
            ]} />
            <Stack direction="row" mb={2} spacing={1.5} alignItems="center">
                {client.isBusiness ? <BusinessIcon /> : <PersonIcon />}
                <Typography variant="h4" align="left">
                    {client.fullName}
                </Typography>
                <Stack spacing={1} direction="row">
                    <IconBarComponent icons={client.icons} fontSize={"large"} />
                </Stack>
            </Stack>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <CustomCard>
                        <CardHeader
                            action={
                                <IconButton onClick={() => history.push(`/clients/${client.id}/edit`)} color="inherit">
                                    <EditIcon />
                                </IconButton>
                            }
                            title={t`Information Generale`} />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {t`Adresse Principale`}
                                    </Typography>
                                    {primaryAddress.formatted}
                                </Grid>
                                {billingAddress && <>
                                    <Grid item xs={12} >
                                        <Typography variant="h6">
                                            {t`Adresse de Facturation`}
                                        </Typography>
                                        {billingAddress.formatted}
                                    </Grid>
                                </>
                                }
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {t`Tel.`}
                                    </Typography>
                                    {client.telephone
                                        ? <a href={`tel://${client.telephone}`}>{client.telephone}</a>
                                        : valueOrNd(null)
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {t`Email`}
                                    </Typography>
                                    {valueOrNd(client.email)}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {t`Réference`}
                                    </Typography>
                                    {valueOrNd(client.reference)}
                                </Grid>
                                {client.isBusiness && <>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {t`Numéro TVA`}
                                        </Typography>
                                        {valueOrNd(client.vatNumber)}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            {t`Entreprise`}
                                        </Typography>
                                        {valueOrNd(client.businessName)}
                                    </Grid>
                                    </>
                                }
                            </Grid>
                        </CardContent>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} sm={12} md={7}
                    sx={{
                        display: { xs: 'none', sm: 'none', 'md': 'block' },
                    }}>
                    <ClientPrimaryAddressMap client={client} />
                </Grid>
            </Grid>

            {/**
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <Link to={`${url}/documents`}>documents</Link>
                    <Link to={`${url}/summary`}>summary</Link>
                    <CustomCard>
                        <Box sx={{ p: 2 }}>
                            <Switch>
                                <Route path={`${path}/documents`}><ClientDocuments documents={documents} /></Route>
                                <Route path={`${path}/summary`}><ClientSummary /></Route>
                            </Switch>
                        </Box>
                    </CustomCard>
                </Grid>
            </Grid>
            */}

            {me.profile.organization.id === "1" && (
                <CustomCard>
                    <CardHeader
                        title="Fiches de travail"
                        action={
                            <IconButton
                                onClick={async () => {
                                    const { data } = await createGseWorkOrder({ variables: { clientId: client.id }})
                                    const workOrder = data.createGseWorkOrder;
                                    enqueueSnackbar(`Fiche de travail cree`, { variant: 'success' })
                                    history.push(`/gse/workorder/${workOrder.id}/edit`)
                                }}
                                color="inherit"
                                >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        }
                    />
                </CustomCard>
            )}

            <CustomCard>
                <CardHeader
                    title={t`Adresses`}
                    action={
                        <IconButton onClick={() => history.push(`/clients/${client.id}/addaddress`)} color="inherit">
                            <AddCircleOutlineIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    {addresses.map((address, i) => {
                        if (address.systems.length === 0) {
                            return <><Trans>Pas de système à cette adresse</Trans>: <br /> {address.formatted}
                                <Stack
                                    direction={screenSize.dynamicWidth > 676 ? "row" : "column"}
                                    spacing={screenSize.dynamicWidth > 676 ? 1 : 0}
                                >
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            variant="outlined"
                                            onClick={() => history.push(`/clients/${client.id}/addsystem/${address.id}`)}
                                            startIcon={<AddCircleOutlineIcon />}
                                        >
                                            {t`ajouter un systeme`}
                                        </Button>
                                    </Box>
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            variant="outlined"
                                            onClick={() => history.push(`/clients/${client.id}/addappointment/${address.id}`)}
                                            startIcon={<AddCircleOutlineIcon />}
                                        >
                                            {t`ajouter un rdv`}
                                        </Button>
                                    </Box>
                                    <Box py={screenSize.dynamicWidth > 676 ? 2 : 1}>
                                        <Button
                                            fullWidth={screenSize.dynamicWidth > 676 ? false : true}
                                            onClick={() => history.push(`/clients/${client.id}/editaddress/${address.id}`)}
                                            color="primary"
                                            startIcon={<EditIcon />}
                                            variant="outlined">
                                            {t`Modifier`}
                                        </Button>
                                    </Box>
                                </Stack>
                            </>
                        }
                        return <>
                            {i !== 0 && (<Box my={2}><Divider light /></Box>)}
                            <AddressSystemsTable
                                systems={address.systems}
                                addressFormatted={address.formatted}
                                clientId={id}
                                addressId={address.id}
                            />
                        </>
                    })}
                </CardContent>
            </CustomCard>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Comments
                        comments={client.comments}
                        currentComment={comment}
                        handleChange={handleCommentChange}
                        handlePostComment={handlePostComment}
                        handleUpdateComment={handleUpdateComment}
                        handleDeleteComment={handleDeleteComment}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DocumentUpload
                        handleChange={handleFileChange}
                        handleDeleteDoc={handleDeleteDoc}
                        documents={documents}
                    />
                </Grid>
            </Grid>
            {/* <ClientMutationLogsTable id={id} /> */}
        </Container>
    </>
}



export default ClientDetails;
