import React, { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { t } from '@lingui/macro'

import {
    CustomCard,
    BasicBreadcrumbs,
    MyTextField,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading,
} from './Common'

import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import { SYSTEM_QUERY } from "../Queries";
import moment from 'moment';
import 'moment/locale/fr'


const AddSim = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { params } = useRouteMatch();
    const history = useHistory()
    const { id } = params;
    const [simConfig, setSimConfig] = useState({
        operateur: "",
        dateActivation: moment().format("YYYY-MM-DD"),
        number: "",
        comments: "",
    })

    const { data, loading, refetch } = useQuery(SYSTEM_QUERY, { variables: { id: id } })

    const [addSim] = useMutation(gql`
        mutation Addsim($id:ID!, $config:SimInput!){
            createSim(id:$id, config:$config){
            id
            }
        }
        `)

    if (loading) return <Loading />

    const handleFormChange = (key, val) => {
        setSimConfig({ ...simConfig, [key]: val })
    }

    const handleSubmit = async () => {
        await addSim({
            variables:{
                id:id,
                config: simConfig,
            }
        })
        await refetch()
        enqueueSnackbar(t`Sim ajoutée'`, {variant:'success'});
        history.push(`/systems/${id}/view`)
    }
    return <>
        <Container maxWidth="md" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${data?.system?.client?.id}/view`, `${data?.system?.client?.fullName}`],
                [`/systems/${data?.system?.id}/view`, `${data?.system?.type}`],
                [t`Ajouter Carte Sim`]
            ]} />
            <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
                <CardHeader
                    title={t`Ajouter Carte Sim`} />
                <CardContent>
                    <Grid container spacing={2}>

                        {/* <Grid item xs={12} ml={0.5}>
                            <FormControlLabel
                                label="Sim GSE"
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("gse", !simConfig.gse)}
                                        checked={simConfig.gse}
                                    />
                                }
                            />
                        </Grid> */}

                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Operateur`}
                                onChange={(e) => handleFormChange("operateur", e.target.value)}
                                value={simConfig.operateur}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Numero SIM`}
                                autocomplete='off'
                                onChange={(e) => handleFormChange("number", e.target.value)}
                                value={simConfig.number}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                mask="__/__/____"
                                label={t`Date d'activation`}
                                onChange={value => handleFormChange("dateActivation", moment(value).format("YYYY-MM-DD"))}
                                value={simConfig.dateActivation}
                                fullWidth
                                variant="outlined"
                                renderInput={(params) => <MyTextField {...params} />}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <DatePicker
                                mask="__/__/____"
                                label="Date d'alerte"
                                onChange={value => handleFormChange("dateAlerte", moment(value).format("YYYY-MM-DD"))}
                                value={simConfig.dateAlerte}
                                fullWidth
                                variant
                                renderInput={(params) => <MyTextField {...params} />}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Notes`}
                                onChange={(e) => handleFormChange("comments", e.target.value)}
                                value={simConfig.comments}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>
                    <CardButtons>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>
        </Container>
    </>
}

export default AddSim;
