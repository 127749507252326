import gql from 'graphql-tag';

export const CLIENTS_QUERY = gql`
query ClientsQuery( $searchBox: String, $skip: Int, $clientsPerPage: Int ) {
  searchClients( search: $searchBox, skip: $skip, first: $clientsPerPage ) {
      count
      clients {
          id
          firstName
          lastName
          fullName
          reference
          email
          telephone
          isBusiness
          vatNumber
          businessName
          icons {
            name
            color
            label
          }
          primaryAddress{
              id
              formatted
              street
              number
              postalCode
              city
          }
      }
  }
} `

export const CLIENT_QUERY = gql`
  query ClientQuery($id:ID!){
    client(id:$id){
      id
      firstName
      lastName
      fullName
      email
      telephone
      isBusiness
      vatNumber
      businessName
      reference
      createdAt
      dateModifiation
      icons {
        name
        color
        label
      }
      addresses {
        id
        formatted
        number
        street
        city
        postalCode
        city
        country
        systems {
          id
          type
          config
          identifier
          dateInstallation
          omnium
          contratEntretien
          maintenanceDueDate
          maintenanceCycleDays
          icons {
            name
            color
            label
          }
          sim {
            id
          }
          template {
            id
            schema
          }
          comments{
            id
            text
            createdBy {
                id
                firstName
            }
            createdAt
          }
          installers {
              id
              firstName
          }
          address {
              id
              formatted
          }
          appointments {
              id
              datetime
              technicians {
                  id
                  firstName
              }
          }
        }
      }
      primaryAddress {
          id
          formatted
          number
          street
          city
          postalCode
          city
          country
          lat
          lng
      }
      billingAddress {
          id
          formatted
          number
          street
          city
          postalCode
          city
          country
      }
      documents {
          id
          titre
          file
          dateUpload
          uploadedFilename
      }
      appointments {
          id
          datetime
          technicians {
              id
              firstName
          }
      }
      comments{
        id
        text
        createdAt
        createdBy {
          id
          firstName
        }
      }
    }
  }
  
`

export const SYSTEM_QUERY = gql`
query SystemQuery($id:ID!){
    system(id:$id){
      id
      dateInstallation
      createdAt
      dateModification
      icons {
        name
        color
        label
      }
      installers {
        id
        firstName
      }
      template {
        id
        type
        schema
      }
      client {
        id
        firstName
        lastName
        fullName
        icons {
          name
          color
          label
        }
      }
      address {
        id
        formatted
      }
      omnium
      contratEntretien
      type
      identifier
      maintenanceDueDate
      maintenanceCycleDays
      lastMaintenanceDate
      comments {
        id
        text
        createdBy {
          id
          firstName
        }
        createdAt
      }
      documents {
        id
        file
        createdBy {
          id
          firstName
        }
        dateUpload
        titre
        uploadedFilename
      }
      sim {
        id
        operateur
        dateActivation
        number
        comments
      }
      maintenanceLogs{
        id
        notes
        entryDate
        installers {
          id
          firstName
        }
        createdBy{
          id
          firstName
        }
      }
      config
    }}
`
export const UPDATE_CLIENT = gql`
  mutation EditClientMutation(
    $firstName:String!,
    $lastName:String!, 
    $reference:String!, 
    $email:String!, 
    $telephone:String!,
    $primaryAddress: AddressInput,
    $billingAddress: AddressInput,
    $primaryAddressId:ID,
    $billingAddressId:ID,
    $clientId:ID!,
    $vatNumber:String!,
    $businessName:String!,
    $isBusiness:Boolean!,
    ){
    updateClient(
      firstName:$firstName, 
      lastName:$lastName, 
      reference:$reference, 
      email:$email, 
      telephone:$telephone,
      primaryAddress:$primaryAddress,
      billingAddress:$billingAddress,
      primaryAddressId:$primaryAddressId,
      billingAddressId:$billingAddressId,
      clientId:$clientId,
      vatNumber:$vatNumber,
      businessName:$businessName,
      isBusiness:$isBusiness,
      ){
        id
        firstName
        lastName
        email
        primaryAddress{
          id
          street
        }
        billingAddress{
          id
          street
        }
    }
  }
`
export const ADD_SYSTEM = gql`
mutation AddSytemMutation(
  $clientId:ID!, 
  $templateId: ID!,
	$contratEntretien:Boolean!,
  $dateInstallation:String!,
  $omnium:Boolean!,
  $installers:[ID]!,
  $addressId:ID,
  $sim: SimInput,
  $config: GenericScalar,
){
  addSystem(
    addressId:$addressId,
    clientId:$clientId,
    templateId: $templateId,
    contratEntretien:$contratEntretien,
    dateInstallation:$dateInstallation,
    omnium:$omnium,
    installers:$installers,
    config:$config
    sim:$sim
  ){
    id
  }
}
`

export const UPDATE_SYSTEM = gql`
  mutation UpdateSystemMutation(
    $systemId:ID!, 
    $addressId:ID!, 
    $contratEntretien:Boolean!, 
    $omnium:Boolean!,
    $dateInstallation:String!,
    $installers:[ID]!,
    $identifier:String,
  ){
    updateSystem(
      systemId:$systemId,
      addressId:$addressId,
      contratEntretien:$contratEntretien,
      omnium:$omnium,
      dateInstallation:$dateInstallation,
      installers:$installers
      identifier:$identifier
    )
    {
      id
    }
  }
`
export const ADD_MAINTENANCE_LOG = gql`
mutation AddMaintenanceLog($systemId:ID!, $notes:String!, $installers:[ID]!, $entryDate:String!){
  createMaintenanceLog(installers:$installers, systemId:$systemId, notes:$notes, entryDate:$entryDate){
    id
    notes
    installers{
      id
      firstName
    }
  }
}`

export const UPDATE_MAINTENANCE_LOG = gql`
mutation updateMaintenanceLog($id:ID!, $notes:String!, $installers:[ID]!, $entryDate:String!){
  updateMaintenanceLog(installers:$installers, id:$id, notes:$notes, entryDate:$entryDate){
    id
  }
}`