import React, { useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';

import { CustomCard, BasicBreadcrumbs, CardButtons, GoBackButton, SaveButton, Loading } from './Common'

import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { useSnackbar } from 'notistack';

import { t } from '@lingui/macro'

import {
    SystemConfigForm,
} from './SystemTemplate'


const EditSystemConfig = () => {
    const { enqueueSnackbar, } = useSnackbar();
    const { params } = useRouteMatch();
    const history = useHistory()
    const { id } = params;
    const [systemConfig, setSystemConfig] = useState({})

    const { data, loading } = useQuery(gql`
    query SystemQuery($id:ID!){
        system(id:$id){
          id
          config
          client {
              id
              fullName
          }
          template {
              id
              type
              schema
          }
          sim {
              id
              operateur
              dateActivation
              number
              comments
          }
        }}
    `, {
        variables: {
            id: id,
        },
        onCompleted: data => {
            setSystemConfig(data.system.config);
        }
    })

    const [UpdateSystemConfig] = useMutation(gql`
        mutation UpdateConfig($systemId:ID!, $config:GenericScalar){
            updateSystemConfig(systemId:$systemId, config:$config){
              id
              config
            }
        }
    `)

    const handleSubmit = async () => {
        await UpdateSystemConfig({
            variables: {
                systemId: id,
                config: systemConfig,
            }
        })
        enqueueSnackbar(t`Configuration modifiée`, {variant:'success'});
        history.push(`/systems/${id}/view`)
    }

    if (loading) return <Loading />
    return <>

        <Container maxWidth="md" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${data?.system?.client?.id}/view`, `${data?.system?.client?.fullName}`],
                [`/systems/${data?.system?.id}/view`, `${data?.system?.template?.type}`],
                [t`Modification`]
            ]} />
            <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
                <CardHeader title={t`Modifier Configuration Système`} />
                <CardContent>
                    <SystemConfigForm
                        values={systemConfig}
                        onChange={newConfig => setSystemConfig(newConfig)}
                        schema={data.system.template.schema}
                        />
                    <CardButtons>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>
        </Container>
    </>
}

export default EditSystemConfig;
