import { ReactSketchCanvas } from 'react-sketch-canvas'
import { useRef } from 'react'

import { MyTextField } from './Common';
import {
    Box,
    CardHeader,
    Button,
    Typography,
    // Stack,
    Alert,
} from '@mui/material'

export const CanvasComponent = () => {
    const ref = useRef()

    const undo = () => {
        ref.current.undo();
    }

    return (
        <>
            <ReactSketchCanvas
                style={{
                    border: '0.0625rem solid #9c9c9c',
                    borderRadius: '0.25rem',
                }}
                width={600}
                height={400}
                strokeWidth={2}
                strokeColor="blue"
                ref={ref}
                />

            <Button
                onClick={undo}
                variant="contained"
                color="secondary"
                >UNDO</Button>
        </>
    )
}

