const hostname = window.location.hostname

const backendUrl = (hostname === "app.gse.be")
    ? "https://app.gse.be"
    : hostname === 'app.consolarium.com'
    ? 'https://app.consolarium.com'
    : `http://${hostname}:8000`;

const graphqlUri = `${backendUrl}/graphql`

export const Settings = {
    MEDIA_URL: `${backendUrl}/media/`,
    ACCESS_TOKEN: 'gsereact.accessToken',
    GRAPHQL_URI: graphqlUri,
    LOGIN_URL: `${backendUrl}/accounts/google/login/`,
    HOME_PAGE: '/clients',
    BACKEND_URL: backendUrl,
}