import React from "react";
import { useMutation, gql } from "@apollo/client";
import { Settings } from '../Settings'

import Button from '@mui/material/Button';


const ExportXlsx = () => {
    const [CreateTempToken, { loading }] = useMutation(gql`
    mutation CreateTempToken{
        createTempToken{
          id
          uuid
        }
      }
    `, {
        onCompleted: (data) => {
            window.open(`${Settings.BACKEND_URL}/export/${data.createTempToken.uuid}`, "_blank")
        }
    })

    if (loading) return "Loading..."
    const handleExport = () => {
        CreateTempToken()
    }


    return <>
        <Button onClick={handleExport}>Export</Button>
    </>
}

export default ExportXlsx;