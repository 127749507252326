import { AddressForm, ConfirmDialog, DEFAULT_ADDRESS } from "./Common";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { useSnackbar } from 'notistack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton'

import { Trans, t } from '@lingui/macro'

import {
    CardHeader,
    CardContent,
    Container,
    Grid,
} from '@mui/material';

import {
    CustomCard,
    BasicBreadcrumbs,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading,
} from "./Common";

import { useMe } from "../CurrentUserContext";


export const EditAddress = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { params } = useRouteMatch()
    const { me } = useMe()
    const { addressId, id } = params
    const [open, setOpen] = useState(false)
    const [currentAddress, setCurrentAddress] = useState({ ...DEFAULT_ADDRESS })
    const { data, loading, error } = useQuery(gql`
    query AddressQuery ( $id: ID! ) {
        address(id:$id){
            id
            number
            street
            city
            postalCode
            country
            client {
              id
              fullName
            }
        }
    }
    `, {
        variables: { id: addressId },
        onCompleted: data => {
            const { address } = data

            setCurrentAddress({
                number: address.number,
                street: address.street,
                city: address.city,
                postalCode: address.postalCode,
                country: address.country,
            })
        },
    })
    const { refetch } = useQuery(gql`
        query ClientQuery($id:ID!){
        client(id:$id){
            id
            addresses {
            id
            formatted
            number
            street
            city
            postalCode
            city
            country
            }
          }
        }
    `, { variables: { id } })


    const [EditAddress] = useMutation(gql`
    mutation EditAddressMutation($addressId:ID!, $config:AddressInput!){
        editAddress(addressId:$addressId, config:$config){
          id
        }
      }
    `)

    const [deleteAddress] = useMutation(gql`
    mutation Delete($addressId:ID){
        delete(addressId:$addressId){
          ok
        }
      }
    `)

    const onSubmit = async () => {
        await EditAddress({ variables: { config: currentAddress, addressId } })
        await refetch()
        enqueueSnackbar(t`Adresse modifiée`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }

    const onDelete = async () => {
        await deleteAddress({
            variables: {
                addressId
            }
        })
        await refetch()
        enqueueSnackbar(t`Adresse supprimée`, { variant: 'success' });
        history.push(`/clients/${id}/view`)
    }
    if (loading) return <Loading />
    if (error) return 'error'
    return (
        <>
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${id}/view`, `${data.address.client.fullName}`],
                    [t`Modifier Adresse`]
                ]} />
            </Container>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <CustomCard>
                    <CardHeader
                        title={t`Modifier Adresse`}
                        action={me.profile.mayDeleteClient &&
                            <IconButton onClick={() => setOpen(true)} color="inherit">
                               <DeleteOutlineIcon />
                           </IconButton>
                       }
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            <AddressForm address={currentAddress} onChange={a => setCurrentAddress(a)} />
                        </Grid>
                        <CardButtons>
                            <GoBackButton />
                            <SaveButton onClick={onSubmit} disabled={loading}>
                                {t`Sauvgarder`}
                            </SaveButton>
                        </CardButtons>
                    </CardContent>
                </CustomCard>
                <ConfirmDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    title={t`Suppression Adresse`}
                    body={<Trans>
                        Attention vous êtes sur le point de supprimer cette adresse.<br />
                        Les éléments suivants relié à cette adresse seront également supprimés:
                        <ul>
                            <li>systèmes</li>
                            <li>rendez-vous</li>
                            <li>entretiens</li>
                            <li>commentaires</li>
                            <li>sim </li>
                            <li>documents</li>
                        </ul>
                        Veuillez confirmer l'action de suppression.
                    </Trans>}
                    onSubmit={onDelete}
                />
            </Container>
        </>
    )
}
