import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { NavLink } from 'react-router-dom'
import { Loading } from './Common'


const AddressIssue = ({ instance }) => (
    <>
        <NavLink to={`/demo/address/${instance.id}/edit`}>link to address</NavLink>
    </>
)

const SystemIssue = ({ instance }) => (
    <>
        <NavLink to={`/systems/${instance.id}/view`}>link to system</NavLink>
        <div>date d'installation: {instance.dateInstallation}</div>
        <div>date limite du prochain entretien: {instance.maintenanceDueDate}</div>
    </>
)

const AppointmentIssue = ({ instance }) => (<>@TODO...</>)

const ISSUSE_DESCRIPTION_MAP = {
    MISSING_REQUIRED_FIELD: 'Info manquante',
    MISSING_TECHNICIAN: 'RDV non assignee',
    MISSING_MAINTENANCE_LOG: 'RDV précédent sans log',
    MISSING_INSTALLATION_DATE: 'Systeme sans date d\'installation',
    MISSING_MAINTENANCE_DUE_DATE: 'Systeme sans date limite',
}

export const IssueListing = () => {
    const { data, error, loading } = useQuery(gql`
      query {
        dataConsistencyIssues {
          description
          instance {
            __typename
            ... on AddressType {
              id
              number
              street
              postalCode
              city
            }
            ... on SystemType {
              id
              dateInstallation
              maintenanceDueDate
              address {
                id
                number
                street
                postalCode
                city
              }
            }
            ... on AppointmentType {
              id
              type
              address {
                id
                number
                street
                postalCode
                city
              }
            }
          }
        }
      }
    `);
    if (loading) return <Loading />
    if (error) return 'error'
    const { dataConsistencyIssues } = data
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th>Raison</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    {dataConsistencyIssues.map((issue, i) => {
                        return (
                            <tr>
                                <td style={{ border: '1px solid black' }}>{ISSUSE_DESCRIPTION_MAP[issue.description]}</td>
                                <td style={{ border: '1px solid black' }}>
                                    {issue.instance.__typename === 'AddressType' && (<AddressIssue instance={issue.instance} />)}
                                    {issue.instance.__typename === 'SystemType' && (<SystemIssue instance={issue.instance} />)}
                                    {issue.instance.__typename === 'AppointmentType' && (<AppointmentIssue instance={issue.instance} />)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}