import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";

import {
    Container,
    CardHeader,
    Button,
    Box,
    Stack,
} from '@mui/material'

import {
    Loading,
    CustomCard,
    BasicBreadcrumbs,
    MyTextField,
    ConfirmDialog,
} from './Common'

import { useSnackbar } from 'notistack';

import { Settings } from '../Settings'

import { UPDATE_GSE_WORK_ORDER_MUTATION } from "./WorkOrders";

import {
    WO_STATE_MAP,
} from './WorkOrders'
import { useMe } from "../CurrentUserContext";


const GSE_DEFAULT_EMAIL = `Cher Client,

En pièce jointe, vous trouverez la fiche relative à l'intervention effectuée sur votre système.
L'équipe GSE.be vous remercie pour votre confiance et reste à votre disposition pour toutes questions ou complément d'information.

Bien cordialement.

Patrick Fondu
Administrateur.
GSE.be`


export const DocumentComponent = () => {
    const { enqueueSnackbar } = useSnackbar()
    // const history = useHistory()
    const { params } = useRouteMatch()
    const { documentId } = params

    const [showEmailForm, setShowEmailForm] = useState(false)
    const [emailTo, setEmailTo] = useState('')
    const [emailMessage, setEmailMessage] = useState(GSE_DEFAULT_EMAIL)

    const [ updateGseWorkOrderState ] = useMutation(UPDATE_GSE_WORK_ORDER_MUTATION)

    const { data, error, loading, refetch } = useQuery(gql`
        query DocumentQuery ( $documentId: ID! ) {
            document ( documentId: $documentId ) {
                id
                titre
                dateUpload
                file
                client {
                    id
                    fullName
                    email
                }
                workOrder {
                    id
                    state
                }
            }
        }
    `, {
        variables: {
            documentId,
        },
        onCompleted: data => {
            setEmailTo(data.document.client.email || '')
        },
    })

    const [ sendGseWorkorderEmail ] = useMutation(gql`
        mutation SendGseWorkorderEmailMutation ( $to: String!, $message: String!, $workorderId: ID! ) {
            sendGseWorkorderEmail ( to: $to, message: $message, workorderId: $workorderId ) {
                sent
            }
        }
    `)

    const { me } = useMe();

    const [showConfirmCloseDialog, setShowConfirmCloseDialog ] = useState(false)

    if (loading) return <Loading />
    if (error) return 'error...'

    const { document } = data

    const isPdf = document.file.endsWith(".pdf");

    const sendEmail = async () => {
        const { data } = await sendGseWorkorderEmail({
            variables: {
                to: emailTo,
                message: emailMessage,
                workorderId: document.workOrder.id,
            },
        })
        const { sent } = data.sendGseWorkorderEmail
        enqueueSnackbar(sent ? `Message sent` : `Message send failed`)
        setShowEmailForm(false);
    }

    return (
        <>
            <Container component="main" maxWidth={false} sx={{ mb: 4 }}>
                <BasicBreadcrumbs pages={[
                    [`/clients/${document.client.id}/view`, `${document.client.fullName}`],
                    [`Document ${document.id}`]
                ]} />

                {document.workOrder?.id && (
                    <CustomCard>
                        <CardHeader title="Fiche de travail" />

                        <Box sx={{ p: 2 }}>
                            <>Etat: {WO_STATE_MAP[document.workOrder.state]}</>
                        </Box>

                        <Stack direction="column" spacing={2} sx={{ px: 2, pb: 2 }}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" onClick={e => setShowEmailForm(true)}>Envoyer au client par e-mail</Button>
                                <>
                                    <Button
                                        variant="contained"
                                        disabled={!me.profile.mayCloseWorkOrders}
                                        onClick={() => setShowConfirmCloseDialog(true)}
                                        >CLÔTURER</Button>
                                    <ConfirmDialog
                                        open={showConfirmCloseDialog}
                                        title={"Confirmation requis"}
                                        body={`...`}
                                        onClose={() => setShowConfirmCloseDialog(false)}
                                        onSubmit={async () => {
                                            await updateGseWorkOrderState({variables: {
                                                id: document.workOrder.id,
                                                data: {},
                                                state: 'CLOSED',
                                            }})
                                            await refetch();
                                        }}
                                    />
                                </>
                            </Stack>
                            {showEmailForm && (
                                <>
                                    <MyTextField
                                        label={'Recipient'}
                                        value={emailTo}
                                        onChange={e => setEmailTo(e.target.value)}
                                    />
                                    <MyTextField
                                        label={'Message'}
                                        value={emailMessage}
                                        onChange={e => setEmailMessage(e.target.value)}
                                        multiline
                                        rows={10}
                                    />
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained" onClick={() => sendEmail()}>ENVOYER</Button>
                                        <Button variant="outlined" onClick={() => setShowEmailForm(false)}>Annuler</Button>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </CustomCard>
                )}

                {isPdf && (
                    <div>
                        <iframe style={{ width: '100%', minHeight: 800 }} src={`${Settings.MEDIA_URL}/${document.file}`} />
                    </div>
                )}
            </Container>
        </>
    )
}
