import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { frFR, enUS, nlNL } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import { Trans, t } from '@lingui/macro'

import {
    Box,
    Card,
    CardContent,
    CardActions,
    Paper,
    Typography,
}
    from '@mui/material'


import {
    MyTextField,
    valueOrNd,
    useLocalStorage,
    Loading,
    ZenDataGrid,
    useCurrentScreenSize,
    IconBarComponent,
} from './Common'

import { CLIENTS_QUERY } from "../Queries";


const Clients = () => {
    const [lang] = useLocalStorage('lang', 'fr'); // const { me } = useMe(); // @TODO move the language conf entirely onto the user profile
    // and have the context update the app after a language change! 
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [typingTimer, setTypingTimer] = useState();
    const typingInterval = 1000;
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchBox, setSearchBox] = useState('')
    const [skip, setSkip] = useState(0)
    const [clientsPerPage, setClientsPerPage] = useLocalStorage('clients.clientsPerPage', 10)
    const { data, loading, error, refetch } = useQuery(CLIENTS_QUERY, {
        variables: {
            skip,
            clientsPerPage,
        }
    })

    // const [screenSize, getDimension] = useState({
    //     dynamicWidth: window.innerWidth,
    //     dynamicHeight: window.innerHeight
    // })

    // const setDimension = () => {
    //     getDimension({
    //         dynamicWidth: window.innerWidth,
    //         dynamicHeight: window.innerHeight
    //     })
    // }

    // useEffect(() => {
    //     window.addEventListener('resize', setDimension);
    //     return (() => {
    //         window.removeEventListener('resize', setDimension);
    //     })
    // }, [screenSize])

    const screenSize = useCurrentScreenSize()

    const doneTyping = () => {
        refetch({
            skip,
            clientsPerPage,
            searchBox
        })
        setSearchLoading(false)
    }

    useEffect(() => {
        setTypingTimer(clearTimeout(typingTimer))
        setTypingTimer(setTimeout(doneTyping, typingInterval))
    }, [searchBox, typingInterval])

    if (loading) return <Loading />
    if (error) return <pre>ERROR: {JSON.stringify(error)}</pre>

    const { clients, count: clientsCount } = data.searchClients;

    const handlePageChange = (number) => {
        setPage(number)
        setSkip(clientsPerPage * number)
    }

    const handlePageSizeChange = (number) => {
        setClientsPerPage(number)
    }

    const handleSearch = (val) => {
        setSearchBox(val)
        setSearchLoading(true)
    }

    const columns = [
        {
            field: "Type",
            headerName: ``,
            sortable: false,
            width: 30,

            disableClickEventBubbling: true,
            renderCell: (params) => {
                if (params.row.isBusiness) {
                    return <BusinessIcon />
                }
                else {
                    return <PersonIcon />
                }
            }
        },
        {
            field: 'name',
            headerName: t`Nom`,
            sortable: false,
            minWidth: 200,
            flex: 0.2,
            renderCell: (params) => `${params.row.firstName} ${params.row.lastName}`
        },
        { field: 'telephone', headerName: t`Téléphone`, minWidth: 130, sortable: false, },
        {
            field: "info",
            headerName: t`Info`,
            sortable: false,
            minWidth: 200,

            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} direction="row">
                        <IconBarComponent icons={params.row.icons} />
                    </Stack>
                );
            }
        },
        { field: 'reference', headerName: t`Réference`, minWidth: 130, sortable: false, },
        {
            field: 'address',
            headerName: t`Adresse`,
            flex: 1,
            sortable: false,
            minWidth: 300,
            valueGetter: (params) => (`
            ${params.row.primaryAddress?.formatted} 
            `)
        },

    ];

    const LOCALE_TEXT_MAP = {
        'fr': frFR.components.MuiDataGrid.defaultProps.localeText,
        'en': enUS.components.MuiDataGrid.defaultProps.localeText,
        'nl': nlNL.components.MuiDataGrid.defaultProps.localeText,
    }

    const localeText = LOCALE_TEXT_MAP[lang]

    return <>
        <Stack spacing={2} mb={1} direction={screenSize.dynamicWidth > 700 ? "row" : "column"}>
            <MyTextField
                sx={{ minWidth: '20em' }}
                id="outlined-basic"
                label={t`Recherche ...`}
                variant="outlined"
                value={searchBox}
                onChange={(e) => handleSearch(e.target.value)}
            />
            <Button variant="outlined"
                sx={{ minWidth: '20em' }}
                onClick={() => history.push("/clients/add")}
                style={{ marginBottom: 5, marginTop: 5 }}
                startIcon={<AddIcon />}>{t`Ajouter Client`}</Button>
        </Stack>
        {screenSize.dynamicWidth > 700 ?
            <>
                <Paper style={{ width: '100%' }}>

                    <ZenDataGrid
                        rows={clients}
                        loading={searchLoading}
                        columns={columns}

                        disableColumnMenu
                        disableColumnFilter
                        disableColumnSelector
                        onRowClick={(e) => history.push(`/clients/${e.row.id}/view`)}
                        // sortingMode='server' 
                        // onSortModelChange={handleSortModelChange}
                        paginationMode="server"
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        pageSize={clientsPerPage}
                        rowsPerPageOptions={[10, 25, 50]}
                        rowCount={clientsCount}
                        page={page}
                        isRowSelectable={() => true}
                        // density="compact" // "compact" | "standard" | "comfortable"
                        autoHeight
                        hideFooterRowCount
                        localeText={localeText}
                    />
                </Paper>
            </>
            :
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                // justifyContent: 'space-around',
                // justifyContent: 'center',
                justifyContent: 'flex-start',
                alignItems: 'center',
                // border: '1px solid yellow',
            }}
            // spacing={5}
            >
                {clients.map((client, i) => (
                    <Box mb={2} mr={2} key={i} sx={{ width: "100%" }}>
                        <Card >
                            <CardContent>
                                <Stack direction="row" spacing={1}>
                                    {client.isBusiness ? <BusinessIcon /> : <PersonIcon />}
                                    <Typography>{client.fullName}</Typography>
                                </Stack>
                                <Stack spacing={1} direction="row">
                                    <IconBarComponent icons={client.icons} />
                                </Stack>
                                {client.telephone ?
                                    <Button
                                        // variant="contained"
                                        href={`tel:${client.telephone}`}
                                        startIcon={<PhoneIcon />}
                                    >{client.telephone}</Button>

                                    : valueOrNd(null)
                                }
                                <Typography>{client.primaryAddress?.formatted}</Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="outlined"
                                    onClick={() => history.push(`/clients/${client.id}/view`)}
                                >
                                    <Trans>Fiche Client</Trans>
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                ))}
            </Box>
        }
    </>
}
export default Clients;


export const WorkOrderComponent = () => {
    // Fiche de travail ...
}