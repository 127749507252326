import { AddressForm, DEFAULT_ADDRESS } from "./Common";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useState } from "react";
import { useSnackbar } from 'notistack';

import { t } from '@lingui/macro'

import {
    CardHeader,
    CardContent,
    Container,
    Grid,
} from '@mui/material';

import {
    CustomCard,
    BasicBreadcrumbs,
    CardButtons,
    GoBackButton,
    SaveButton,
    Loading,
} from "./Common";


export const AddAddress = () => {
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory()
    const { params } = useRouteMatch()
    const { id } = params
    const { refetch } = useQuery(gql`
    query ClientQuery($id:ID!){
      client(id:$id){
        id
        addresses {
          id
          formatted
          number
          street
          city
          postalCode
          city
          country
          systems {
            id
            type
            config
            dateInstallation
            omnium
            contratEntretien
            maintenanceDueDate
            maintenanceCycleDays
            sim {
              id
            }
            comments{
              id
              text
              createdBy {
                  id
                  firstName
              }
              createdAt
            }
            installers {
                id
                firstName
            }
            address {
                id
                formatted
            }
            appointments {
                id
                datetime
                technicians {
                    id
                    firstName
                }
            }
          }
        }
      }
    }
  `, { variables: { id: id } })
    const { data, loading, error } = useQuery(gql`
        query ClientInfo ( $id: ID! ) {
            client ( id: $id ) {
                id
                fullName
            }
        }
    `, { variables: { id } })
    const [ AddAddress ] = useMutation(gql`
        mutation AddAddressMutation ( $address: AddressInput!, $clientId: ID! ) {
            newCreateAddress( address: $address, clientId: $clientId ) {
                id
            }
        }
    `)
    const [ address, setAddress ] = useState({...DEFAULT_ADDRESS})
    const onSubmit = async () => {
        await AddAddress({ variables: { address, clientId:id }})
        await refetch()
        enqueueSnackbar(t`Adresse ajoutée`, {variant:'success'});
        history.push(`/clients/${id}/view`)
    }
    if (loading) return <Loading />
    if (error) return 'error'
    const { client } = data
    return (
        <>
            <Container maxWidth="md">
                <BasicBreadcrumbs pages={[
                    [`/clients/${client.id}/view`, `${client.fullName}`],
                    [t`Nouvelle Adresse`]
                ]} />
            </Container>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                <CustomCard>
                    <CardHeader title={t`Nouvelle Adresse`}  >
                    </CardHeader>
                    <CardContent>
                        <Grid container spacing={3}>
                            <AddressForm address={address} onChange={a => setAddress(a)} />
                        </Grid>
                        <CardButtons>
                            <GoBackButton />
                            <SaveButton onClick={onSubmit} disabled={loading}>
                                {t`Sauvgarder`}
                            </SaveButton>
                        </CardButtons>
                </CardContent>
            </CustomCard>
        </Container>
        </>
    )
}
