import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';

import { CustomCard, MultiSelectUsers, BasicBreadcrumbs, CardButtons, GoBackButton, SaveButton, Loading } from './Common'
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro'

import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import moment from 'moment';
import 'moment/locale/fr'

import { ADD_SYSTEM, CLIENT_QUERY } from "../Queries";

import {
    SimConfigFormComponent,
} from './SimConfigForms'

import {
    MyTextField,
} from './Common'

import {
    SystemConfigForm,
} from './SystemTemplate'


const AddSystem = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory()
    const { params } = useRouteMatch();
    const { clientId, addressId } = params;
    const [system, setSystem] = useState({
        clientId,
        templateId: null,
        contratEntretien: false,
        dateInstallation: moment().format('YYYY-MM-DD'),
        omnium: false,
        identifier: "",
        installers: [],
    })
    const [hasSIM, setHasSIM] = useState(false);
    const [simConfig, setSimConfig] = useState({
        operateur: "",
        dateActivation: null,
        dateAlerte: null,
        number: "",
        gse: false,
    })

    const [systemConfig, setSystemConfig] = useState({});

    const [AddSystem, { data: systemData, loading: mutationLoading }] = useMutation(ADD_SYSTEM)
    const { data: client, loading: clientLoading, refetch } = useQuery(CLIENT_QUERY, { variables: { id: clientId } })
    const { data, loading } = useQuery(gql`
        query {
            users {
                id
                username
                firstName
                profile {
                    id
                }
            }
            systemTemplates {
                id
                type
                schema
            }
        }
    `)

    useEffect(() => {
        if (systemData) {
            refetch()
            history.push(`/systems/${systemData.addSystem.id}/view`)
        }
    }, [systemData, history, refetch])

    const [selectedSystemTemplate, setSelectedSystemTemplate] = useState(null)

    if (loading || mutationLoading || clientLoading) return <Loading />


    const handleFormChange = (key, val) => {
        setSystem({ ...system, [key]: val })
    }

    const handleSubmit = async () => {
        let variables = {
            ...system,
            addressId,
            templateId: selectedSystemTemplate.id,
            config: systemConfig,
            sim: hasSIM ? simConfig : null,
        }
        await AddSystem({ variables })
        enqueueSnackbar(t`Systeme ajouté`, { variant: 'success' });
    }

    // const users = data.users.filter(u => u.username !== "root" && u.profile.organization);

    return <>
        <Container maxWidth="md" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${client.client.id}/view`, `${client.client.fullName}`],
                [t`Ajouter Système`]
            ]} />
            <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
                <CardHeader
                    title={t`Ajouter Systeme`} />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MyTextField
                                id="type"
                                label={t`Type`}
                                onChange={(e) => {
                                    setSelectedSystemTemplate(e.target.value)

                                }}
                                value={selectedSystemTemplate}
                                required
                                select
                                >
                                {data.systemTemplates.map(systemTemplate => (
                                    <MenuItem key={systemTemplate.type} value={systemTemplate}>
                                        {systemTemplate.type}
                                    </MenuItem>
                                ))}
                            </MyTextField>
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Identifiant`}
                                onChange={(e) => handleFormChange('identifier',  e.target.value)}
                                value={system.identifier}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DesktopDatePicker
                                label={t`Date d'installation`}
                                inputFormat="DD/MM/yyyy"
                                value={system.dateInstallation}
                                onChange={(e) => handleFormChange("dateInstallation", e.format("YYYY-MM-DD"))}
                                renderInput={(params) => <MyTextField sx={{ width: '100%' }} {...params} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MultiSelectUsers
                                label="Installateurs"
                                selectedUserIds={system.installers}
                                onChange={ids => handleFormChange("installers", ids)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label="Omnium"
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("omnium", e.target.checked)}
                                        checked={system.omnium}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                label={t`Contrat d'entretien`}
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("contratEntretien", e.target.checked)}
                                        checked={system.contratEntretien}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                    {selectedSystemTemplate && (
                        <SystemConfigForm
                            values={systemConfig}
                            schema={selectedSystemTemplate.schema}
                            onChange={newSystemConfig => setSystemConfig(newSystemConfig)}
                            />
                    )}
                    <Grid item xs={12} pt={3} ml={1}>
                        <FormControlLabel
                            label={t`Carte SIM`}
                            control={
                                <Checkbox
                                    onChange={() => setHasSIM(!hasSIM)}
                                    checked={hasSIM}
                                />
                            }
                        />
                    </Grid>
                    {hasSIM && (
                        <SimConfigFormComponent formData={simConfig} onChange={newSimConfig => setSimConfig(newSimConfig)} />
                    )}
                    <CardButtons>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>
        </Container>
    </>
}

export default AddSystem;