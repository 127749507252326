import React, { useState, useEffect } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { t } from '@lingui/macro'

import {
    CustomCard,
    BasicBreadcrumbs,
    MyTextField,
    GoBackButton,
    SaveButton,
    CardButtons,
    AutocompleteOptionsTextField,
    Loading,
} from './Common'

import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Grid from '@mui/material/Grid';
import DatePicker from '@mui/lab/DatePicker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { SYSTEM_QUERY } from "../Queries";

import moment from 'moment';
import 'moment/locale/fr'

const EditSim = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { params } = useRouteMatch();
    const history = useHistory()
    const { id } = params;
    const [open, setOpen] = useState(false)
    const [simConfig, setSimConfig] = useState({
        operateur: "",
        dateActivation: moment().format("YYYY-MM-DD"),
        // dateAlerte: moment().add(11, 'months').format("YYYY-MM-DD"),
        number: "",
        gse: false,
        comments: "",
    })
    const { data, loading, refetch } = useQuery(SYSTEM_QUERY, { variables: { id: id } })
    // const { data, loading, error} = useQuery(gql`
    // query Sim($id:ID!){
    //     sim(id:$id){
    //       id
    //       operateur
    //       number
    //       dateActivation
    //       comments
    //       system {
    //         id
    //         type
    //         client {
    //             id
    //             fullName
    //         }
    //       }
    //   }}`, { variables: { id: id } })

    const [editSim] = useMutation(gql`
        mutation Editsim($id:ID!, $config:SimInput!){
            updateSim(id:$id, config:$config){
                id
                operateur
                dateActivation
                number
                comments
            }
        }
        `)
    const [deleteSim] = useMutation(gql`
        mutation Delete($simId:ID){
            delete(simId:$simId){
              ok
            }
          }
        `)

    useEffect(() => {
        if (data?.system?.sim) {
            setSimConfig({
                ...simConfig,
                operateur: data.system.sim.operateur,
                dateActivation: data.system.sim.dateActivation,
                // dateAlerte: data.system.sim.dateAlerte,
                number: data.system.sim.number,
                // gse: data.system.sim.gse,
                comments: data.system.sim.comments,
            })
        }
    }, [data])

    if (loading) return <Loading />

    const handleFormChange = (key, val) => {
        setSimConfig({ ...simConfig, [key]: val })
    }

    const handleSubmit = async () => {
        await editSim({
            variables: {
                id: data.system.sim.id,
                config: simConfig,
            }
        })
        await refetch()
        enqueueSnackbar(t`Sim modifiée`, {variant:'success'});
        history.push(`/systems/${id}/view`)
    }
    const handleDelete = async () => {
        await deleteSim({variables:{
            simId:data.system.sim.id
        }})
        await refetch()
        enqueueSnackbar(t`Sim supprimée`, {variant:'success'});
        history.push(`/systems/${id}/view`)
    }
    return <>
        <Container maxWidth="md" sx={{ mb: 4 }} >
            <BasicBreadcrumbs pages={[
                [`/clients/${data.system.client.id}/view`, `${data.system.client.fullName}`],
                [`/systems/${data.system.id}/view`, `${data.system.type}`],
                [t`Modifier Sim`]
            ]} />
            <CustomCard sx={{ maxWidth: 'md', mx: 'auto' }}>
                <CardHeader
                    title={t`Modifier Carte SIM`} />
                <CardContent>
                    <Grid container spacing={2}>

                        {/* <Grid item xs={12} ml={0.5}>
                            <FormControlLabel
                                label="Sim GSE"
                                control={
                                    <Checkbox
                                        onChange={(e) => handleFormChange("gse", !simConfig.gse)}
                                        checked={simConfig.gse}
                                    />
                                }
                            />
                        </Grid> */}

                        <Grid item xs={12}>
                            {/**
                            <MyTextField
                                label="Operateur"
                                onChange={(e) => handleFormChange("operateur", e.target.value)}
                                value={simConfig.operateur}
                            />
                            */}

                            <AutocompleteOptionsTextField
                                configKey="sim.operateur"
                                label={t`Operateur`}
                                onChange={newValue => handleFormChange("operateur", newValue)}
                                value={simConfig.operateur}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Numero SIM`}
                                autocomplete='off'
                                onChange={(e) => handleFormChange("number", e.target.value)}
                                value={simConfig.number}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                mask="__/__/____"
                                label={t`Date d'activation`}
                                onChange={value => handleFormChange("dateActivation", moment(value).format("YYYY-MM-DD"))}
                                value={simConfig.dateActivation}
                                fullWidth
                                variant="outlined"
                                renderInput={(params) => <MyTextField {...params} />}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <DatePicker
                                mask="__/__/____"
                                label="Date d'alerte"
                                onChange={value => handleFormChange("dateAlerte", moment(value).format("YYYY-MM-DD"))}
                                value={simConfig.dateAlerte}
                                fullWidth
                                variant
                                renderInput={(params) => <MyTextField {...params} />}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <MyTextField
                                label={t`Notes`}
                                onChange={(e) => handleFormChange("comments", e.target.value)}
                                value={simConfig.comments}
                                multiline
                                rows={3}
                            />
                        </Grid>
                    </Grid>


                    <CardButtons>
                        <Box sx={{ marginRight: "auto" }}>
                            <Button color="error" onClick={() => setOpen(true)}>
                                {t`Supprimer`}
                            </Button>
                        </Box>
                        <GoBackButton />
                        <SaveButton disabled={loading} onClick={handleSubmit} />
                    </CardButtons>
                </CardContent>
            </CustomCard>

            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={"sm"}
                fullWidth
            >
                <DialogTitle>{t`Supprimer Carte SIM`}</DialogTitle>
                <DialogContent>
                    {t`Etes-vous sur de vouloir supprimer la carte sim du système de ${data.system.client.fullName} ?`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t`Annuler`}</Button>
                    <Button color="error" onClick={handleDelete}>{t`Supprimer`}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    </>
}

export default EditSim;
