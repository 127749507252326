import React, { useState, useRef } from 'react';
// import { useRouteMatch, NavLink } from "react-router-dom";
import {
    useQuery,
    gql,
    // useMutation,
} from '@apollo/client';

// import { Wrapper } from "@googlemaps/react-wrapper"; // @TODO can be removed?
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import ReactMapGL, { Marker, FullscreenControl } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';
// import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import '../App.css'

import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Typography,
    Tabs,
    Tab,
    Box,
    Button,
} from '@mui/material';
import Container from '@mui/material/Container';
import {
    // LocalFireDepartmentOutlined,
    // WorkspacePremiumOutlined,
    // FingerprintOutlined,
    ShieldOutlined,
    // PolicyOutlined,
    WarningAmber,
}
from '@mui/icons-material';

import {
    useLocalStorage,
    AddressForm,
    Loading,
} from './Common'

import mapboxgl from "mapbox-gl";


// import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { Switch, Route, useRouteMatch, Redirect, NavLink, 
    // useHistory,
} from 'react-router-dom';

import '../App.css'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


// import MaterialJsonSchemaForm from 'react-jsonschema-form-material-ui';
// import securityAlarmSchema from '../schemas/security_alarm.json';

// const MyPaper = props => {
//     return <Paper {...props} elevation={3} style={{
//         padding: '1rem',
//     }} />
// }

const JsonSchemaDemo = () => {
    // return (
    //     <MaterialJsonSchemaForm
    //       schema={securityAlarmSchema}
    //       />
    // )
}

// const mapboxApiAccessToken = 'pk.eyJ1Ijoic2hlYW4tbWFzc2V5IiwiYSI6ImNreDNpeWM4azB3ZXcybnVxZ3pwNjg4ajYifQ.ghh310nUqLxMbetMM5sMSw'
// const mapboxApiAccessToken = 'pk.eyJ1Ijoic21hc3NleSIsImEiOiJja3h4Y2ljNDU0cGU1Mnh1Ym9meWpveTNnIn0.Lv4S-HXm7PcQ86jdEAAljA'

const mapboxApiAccessToken = 'pk.eyJ1Ijoic2hlYW4tbWFzc2V5IiwiYSI6ImNreDNpeWM4azB3ZXcybnVxZ3pwNjg4ajYifQ.ghh310nUqLxMbetMM5sMSw'

const SystemMarker = ({ system, onHover, dataMapper, onClick, selected, overdue }) => {
    onHover = onHover || (() => {})
    dataMapper = dataMapper || (a => a); // default is an identity function 
    const a = dataMapper(system)
    // const [hovering, setHovering] = useState(false)
    const { palette } = useTheme()
    if ( !(a.lat && a.lng) ) {
        return <></>
    }

    const hasAppointment = system.upcomingMaintenanceAppointments.length > 0

    const getColors = (() => {
        const defaultBgColor = (palette.mode === "dark")
            ? '#0f202d'
            : '#aaa'; 

        const _getColors = () => {
            if (selected) {
                if (hasAppointment) {
                    return {bgcolor: 'secondary.dark', color: 'secondary.contrastColor'}
                } else {
                    return {bgcolor: 'primary.dark', color: 'primary.contrastColor'}
                }
            } else {
                if (hasAppointment) {
                    return {bgcolor: defaultBgColor, color: 'secondary.main'}
                } else {
                    return {bgcolor: defaultBgColor, color: 'primary.main'}
                }
            }
        }

        const c = _getColors();

        // inverse the colors for overdue items:
        if (overdue) return { bgcolor: c.color, color: c.bgcolor }
        return c;
    })

    return (
        <Marker latitude={a.lat} longitude={a.lng} offsetLeft={-20} offsetTop={-10}>
            <Box 
                className={selected ? '' : `pulsate`}
                style={{
                    borderRadius: '2rem',
                    paddingTop: '.3rem',
                }}
                sx={{
                    ...getColors(),
                    cursor: 'pointer',
                }}
                onMouseEnter={() => {
                    onHover(system)
                    // setHovering(true)
                }}
                onMouseLeave={() => {
                    onHover(null)
                    // setHovering(false)
                }}
                onClick={() => onClick(system)}
                >
                {overdue ? <WarningAmber /> : <ShieldOutlined />}
            </Box>
        </Marker>
    )
}

export const SystemMapComponent = ({ systems, overdueSystems, center, fullScreen, onHover, height, dataMapper, selectedSystemIds, onClick }) => {
    onHover = onHover || (() => { console.log('no onHover handler defined') })
    height = height || '100%'
    const mapRef = useRef()
    const [viewport, setViewport] = useState({
        width: 'fit',
        height,
        latitude: center?.lat || 50.8476, // default is center bxl here
        longitude: center?.lng || 4.3572,
        zoom: 9,
    })

    const { palette } = useTheme();

    const mapStyle = (palette.mode === "light")
        ? 'mapbox://styles/mapbox/navigation-day-v1'
        : 'mapbox://styles/mapbox/navigation-night-v1'
        ;

    const fullscreenControlStyle= {
      right: 10,
      top: 10
    };

    const isSelected = system => selectedSystemIds.indexOf(system.id) >= 0;

    return (
        <>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                mapboxApiAccessToken={mapboxApiAccessToken}
                onViewportChange={newViewport => setViewport({...newViewport, width: 'fit'})}
                mapStyle={mapStyle}
                >
                {systems.map((system, i) => 
                    <SystemMarker
                        key={i}
                        selected={isSelected(system)}
                        onClick={onClick}
                        dataMapper={dataMapper}
                        system={system}
                        onHover={a => onHover(a ? system : null)}
                        />
                )}
                {overdueSystems.map((system, i) =>
                    <SystemMarker
                        key={i}
                        selected={isSelected(system)}
                        onClick={onClick}
                        dataMapper={dataMapper}
                        system={system}
                        onHover={a => onHover(a ? system : null)}
                        overdue={true}                   
                        />
                )}
                {fullScreen && <FullscreenControl style={fullscreenControlStyle} />}
            </ReactMapGL>
        </>
    )
}



const AddressMarker = ({ address, onHover, addressMap, onClick }) => {
    onHover = onHover || (() => {})
    addressMap = addressMap || (a => a); // default is an identity function 
    const a = addressMap(address)

    const [hovering, isHovering] = useState(false)

    if ( !(a.lat && a.lng) ) {
        return <></>
    }

    return (
        <Marker latitude={a.lat} longitude={a.lng} offsetLeft={-20} offsetTop={-10}>
            <Box 
                className={hovering ? '' : `pulsate`}
                style={{
                    borderRadius: '2rem',
                    paddingTop: '.3rem',
                }}
                sx={{
                    bgcolor: hovering ? 'primary.main' : 'primary.dark',
                    color: 'primary.contrastText'
                }}
                onMouseEnter={() => {
                    onHover(address)
                    isHovering(true)
                }}
                onMouseLeave={() => {
                    onHover(null)
                    isHovering(false)
                }}
                >
                <ShieldOutlined />
            </Box>
        </Marker>
    )
}

export const AddressMapComponent = ({ addresses, center, fullScreen, onHover, height, addressMap }) => {
    onHover = onHover || (() => { console.log('no onHover handler defined') })
    height = height || '100%'
    const mapRef = useRef()
    const [viewport, setViewport] = useState({
        width: 'fit',
        height,
        // height: '',
        // minHeight: 200,
        latitude: center.lat,
        longitude: center.lng,
        zoom: 9,
    })

    // const STYLES = [
    //     'mapbox://styles/mapbox/streets-v11',
    //     'mapbox://styles/mapbox/outdoors-v11',
    //     'mapbox://styles/mapbox/light-v10',
    //     'mapbox://styles/mapbox/dark-v10',
    //     'mapbox://styles/mapbox/satellite-v9',
    //     'mapbox://styles/mapbox/satellite-streets-v11',
    //     'mapbox://styles/mapbox/navigation-day-v1',
    //     'mapbox://styles/mapbox/navigation-night-v1',
    // ]
    // const [mapStyle, setMapStyle] = useState(STYLES[7])

    const { palette } = useTheme();

    const mapStyle = (palette.mode === "light")
        ? 'mapbox://styles/mapbox/navigation-day-v1'
        : 'mapbox://styles/mapbox/navigation-night-v1'
        ;

    const fullscreenControlStyle= {
      right: 10,
      top: 10
    };

    return (
        <>
            <ReactMapGL
                ref={mapRef}
                {...viewport}
                mapboxApiAccessToken={mapboxApiAccessToken}
                onViewportChange={newViewport => setViewport({...newViewport, width: 'fit'})}
                mapStyle={mapStyle}
                >
                {addresses.map((address, i) => <AddressMarker key={i} addressMap={addressMap} address={address} onHover={a => onHover(a ? address : null)} />)}
                {fullScreen && <FullscreenControl style={fullscreenControlStyle} />}
            </ReactMapGL>
        </>
    )
}

export const DemoMapComponent = () => {
    const { data, error, loading } = useQuery(gql`
        query Addresses ( $hasGeocodes: Boolean ) {
            addresses ( hasGeocodes: $hasGeocodes ) {
                id
                lat
                lng
                street
                number
                postalCode
                city
                country
                systems {
                    id
                    type
                }
            }
        }
    `, { variables: { hasGeocodes: true }})
    if (loading) return <Loading />
    if (error) return 'error'
    const { addresses } = data
    if (addresses.length === 0) return 'no addresses...'
    return <AddressMapComponent fullScreen={true} addresses={addresses} center={addresses[0]} />
}

const LinkTab = props => {
    // const { onClick } = props;
    return (
        <Tab
            component={NavLink}
            {...props}
            />
    )
}

class DemoEvalContainer extends React.Component {
    render() {
        return React.createElement('div', null, `Moot ${this.props.demoString}`);
    }
}

const DemoEvalContainerFunction = props => 
    React.createElement('div', null, `Moot ${props.demoString}`)

const DemoEvalContainerComponent = () => (
    <>
        <DemoEvalContainer demoString={`w00t`} />
        <DemoEvalContainerFunction demoString={`w00t2 \${props.x}`} x={'42'} />
    </>
)

export const DemoRouter = () => {
    const { path, url } = useRouteMatch();
    return (
        <>
            <NavLink to={`${url}/map`}>map demo</NavLink>

            <Switch>
                <Route path={`${path}/map`}><DemoMapComponent /></Route>
                <Route path={`${path}/eval`}><DemoEvalContainerComponent /></Route>
                <Route exact path={`${path}/`}><Redirect to={`${path}/map`} push={false} /></Route>
            </Switch>
        </>
    )
}
