import { useState } from "react";

import { MyTextField } from './Common'

import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { Trans, t } from '@lingui/macro'


const _get = (values, key, defaultValue) => {
    if (key in values) {
        return values[key];
    }
    return defaultValue !== undefined ? defaultValue : "";
}

const shouldDisplay = (fieldDefinition, values) => {
    if (!fieldDefinition.simpleDisplayCondition) return true;
    const { fieldName, operator, value } = fieldDefinition.simpleDisplayCondition;
    if (operator === '==') {
        return (values[fieldName] === value);
    }
    if (operator === '!=') {
        return (values[fieldName] !== value);
    }
    throw new Error('tef fuck is this operator?', operator)
}

export const SystemConfigForm = ({ values, schema, onChange }) => {
    // schema.fields.filter(fieldDef => fieldDef.name != '');
    return (
        <>
            <Grid container spacing={2} mt={1}>
                {schema.fields.map(fieldDefinition => {
                    const { name, displayName, widget, options, placeholder, defaultValue } = fieldDefinition;

                    if (!(name in values)) {
                        onChange({ ...values, [name]: ""});
                        return;
                    }

                    if (!shouldDisplay(fieldDefinition, values)) {
                        if (values[name] !== '') {
                            onChange({ ...values, [name]: ''});
                        }
                        return;
                    }

                    if (widget === 'textfield') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    label={displayName}
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    placeholder={placeholder}
                                    />
                            </Grid>
                        )
                    }

                    /**
                     * @TODO fix this, update to use the new SystemTemplate models.
                     */
                    if (widget === 'autocomplete') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    label={displayName}
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    placeholder={placeholder}
                                    />
                            </Grid>
                        )
                    }

                    if (widget === 'textarea') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    multiline
                                    rows={3} // could be moved to the schema
                                    label={displayName}
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    placeholder={placeholder}
                                    />
                            </Grid>
                        )
                    }

                    if (widget === 'numberfield') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    type="number"
                                    label={displayName}
                                    InputProps={{ inputProps: { min: 0 } }} // this should probably either be removed or made configurable via the schema
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    placeholder={placeholder}
                                    />
                            </Grid>
                        )
                    }

                    if (widget === 'dropdown') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    label={displayName}
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    select
                                    >
                                    {options.map((option, i) => (
                                        <MenuItem key={i} value={option.value}>{option.displayName}</MenuItem>
                                    ))}
                                </MyTextField>
                            </Grid>
                        )
                    }

                    if (widget === 'yes_no_field') {
                        return (
                            <Grid item xs={12} key={fieldDefinition.name}>
                                <MyTextField
                                    label={displayName}
                                    onChange={(e) => onChange({ ...values, [name]: e.target.value })}
                                    value={_get(values, name, defaultValue )}
                                    select
                                    >
                                    <MenuItem value={""}></MenuItem>
                                    <MenuItem value={true}>{t`Oui`}</MenuItem>
                                    <MenuItem value={false}>{t`Non`}</MenuItem>
                                </MyTextField>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </>
    )
}
